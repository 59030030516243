.RYBA4topButtons {
  /* display: flex;
  justify-content: space-around; */
  height: auto;
  opacity: 1;
  /* background-color: #222222; */
  margin-bottom: 35px;
  border: none;
  /* padding: 8px; */
}
.profileButton svg {
  background-color: transparent;
  border-radius: 6px;
  color: white;
}
.profileButton div {
  border-radius: 6px;
  transition: all 0.2s ease;
}
.profileButton:hover div {
  background-color: #212121;
  color: white;
}
.RYBA4title {
  font-weight: 500;
}
.RYBA4topButtons h2 {
  margin-top: 0px;
  margin-bottom: 37px;
}
.RYBA4topButtonsBorder {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid transparent; */
  border-radius: 6px;
  background-color: #121212;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  /* border-radius: 10px; */
  /* margin: 5x; */
  width: 100%;
}
.RYBA4topButtonsBorder2 {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-width: 512px;
  height: fit-content;
  /* border: 1px solid black; */
  background-color: #121212;
  border-radius: 6px;
  /* border-radius: 10px; */
  margin: 5x;
}

.RYBA4topButton {
  border: none;
  border-radius: 10px;
  padding: 4px;
  margin: 5px;
  width: fit-content;
  transition: all 0.15s ease-in-out;
  /* border-bottom-right-radius: 0px; */
}
.RYBA4topButton2 {
  border: none;
  border-radius: 10px;

  padding: 4px;
  margin: 5px;
  width: fit-content;
  transition: all 0.15s ease-in-out;
}
.RYBA4topButton:hover {
  background-color: #767676;
}
.RYBA4topButton:active {
  background-color: #cecc92;
}
.RYBA4ropButtonsSmall {
  display: flex;
  height: 70px;
}

/*////////////////////// RYBA //////////////////////////*/
h2 {
  text-align: center;
  padding: 1px;
}
.RYBA4rateRybaDiv {
  text-align: center;
  margin: 10px;
}
.RYBA4rateRybaDiv button {
  font-size: 16px;
  border-radius: 6px;
  border: none;
  margin: 2px;
  padding: 7px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.15s ease;
}
.RYBA4rateRybaDiv button:hover {
  scale: 1.03;
  cursor: pointer;
}
.RYBA4rybaContainer {
  width: 100%;
  overflow-x: auto;
  padding-top: 10px;
  background: rgb(48, 48, 48);
  height: fit-content;

  padding-bottom: 300px;
}
.RYBA4ryba {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 50px;
  width: 975px;

  height: 100%;
  background: #414141;

  color: #dddddd;
  border-radius: 10px;
  animation: fadeIn 0.6s;
}

@keyframes fadeIn {
  0% {
    background-color: #000;
    background: #000;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
/* SEGMENT style*/
.RYBA4segmentsContainer {
  /* background-color: #dddddd; */
  margin-left: auto;
  margin-right: auto;
  width: 900px;
  /* ШИРИНА */
  /* border-radius: 2px; */
  min-height: 950px;
}
.organ {
  /* border: 0.5px solid rgb(158, 158, 158); */
  display: flex;
  background-color: rgb(43, 43, 43);
}
.organTitle {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 100;
  background-color: rgb(41, 41, 41);
  padding: 4px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
  color: rgb(154, 154, 154);
  width: 250px;
  display: flex;
  justify-content: right;
  align-items: right;
  text-align: right;
  word-break: break-word;
}
.organTitle:hover {
  background-color: rgb(46, 46, 46);
  cursor: pointer;
  color: rgb(241, 241, 241);
}
.organSegments {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sgmnt {
  /* border: 3px solid red; */
  display: flex;
  position: relative;
  height: 100%;
}

.segmentTitleDiv {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 100;
  /* border: 3px solid green; */
  /* background-color: #575757; */
  background-color: rgb(221, 220, 207);
  padding: 4px;
  /* border-radius: 6px; */
  /* margin: 3px; */
  color: rgb(0, 0, 0);
  width: 250px;
  display: flex;
  justify-content: right;
  align-items: right;
  text-align: right;
  border: 1px solid rgb(158, 158, 158);
}
.segmentText {
  width: 100%;
  display: flex;
}
.segmentTextTextarea {
  box-sizing: border-box;
  font-size: 18px;
  width: 100%;
  min-height: 100%;
  border: 0px;
  outline: none;
  background: rgb(222, 222, 222);
  padding-left: 3px;
}
.segmentTextTextarea:hover {
  background: rgb(179, 179, 179);
}

.segmentButtons {
  position: absolute;
  left: 100%;
  border-radius: 6px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(41, 41, 41);
  width: 400px;
  background-color: #1e1e1e;
  margin-left: 8px;
  z-index: -1;
  opacity: 0;
  font-weight: 100;
  box-shadow: 5px 5px 10px 2px rgb(32, 32, 32);
}

.segmentButtons p {
  padding: 2px;
  margin: 0px;
}
.segmentOne {
  background: rgb(50, 49, 49);
  border-radius: 6px;
  border: 1px solid #383838;
  margin: 4px;
  padding: 5px;
}

.segmentOne:hover {
  background-color: #262626;
}

.fieldsetLegend {
  font-weight: 100;
  font-size: 1.2rem;
  color: rgb(136, 136, 136);
  padding: 1px;
  border: none;
}
.segmentButtons label {
  display: block;
}

.segmentButtons select {
  /* border: 1px solid rgb(93, 93, 93); */
  border-radius: 6px;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 5px;
  background-color: #181818;
  color: white;
  outline: none;
  width: 50px;
}

.promptDiv {
  white-space: pre-wrap;
}
.topLeft {
  border-top-left-radius: 6px;
}
.topRight {
  border-top-right-radius: 6px;
}
.bottomLeft {
  border-bottom-left-radius: 6px;
}
.bottomRight {
  border-bottom-right-radius: 6px;
}
.marginBottom {
  margin-bottom: 40px;
}
.constructorWindow fieldset {
  border: 1px solid rgb(101, 101, 101);
  color: rgb(182, 182, 182);
}
.RYBAdinamika {
  border: none;
  width: 100%;
  display: flex;
}
.RYBAdinamika textarea {
  width: 100%;
  min-height: 100%;
  background: rgb(222, 222, 222);

  padding: 25px;
  padding-top: 20px;
  outline: none;
  font-size: 18px;
}
/* DINAMIKA */
.RYBA4segmentsContainerDinamika {
  /* background-color: #dddddd; */
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  /* ШИРИНА */
  /* border-radius: 2px; */
  min-height: 950px;
}
.sgmntDinamika {
  display: flex;
  position: relative;
  height: 100%;
}
.organDinamika {
  /* border: 0.5px solid rgb(158, 158, 158); */
  display: flex;
  justify-content: left;
  justify-items: left;
  background-color: rgb(43, 43, 43, 0);
}
.organSegmentsDinamika {
  width: 0px;
  display: flex;
  flex-direction: column;
}
.segmentButtonsDinamika {
  position: absolute;
  left: 100%;
  border-radius: 6px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(41, 41, 41);
  width: 400px;
  background-color: #1e1e1e;
  /* margin-left: 780px; */
  margin-left: 5px;
  z-index: -1;
  opacity: 0;
  font-weight: 100;
  box-shadow: 5px 5px 10px 2px rgb(32, 32, 32);
}
.RYBA4topButtonsDinamika {
  /* display: flex;
  justify-content: space-around;
  height: auto;
  opacity: 1;

  margin-bottom: 35px;
  border: none; */
  text-align: left;
}
.RYBA4topButtonsSmallAndTxtFormat {
  display: flex;
  flex-direction: column;
  padding-top: 2px;
}
.dinamikaAndPrimary {
  display: flex;
  flex-direction: row;
}
.dinamika {
  margin: 5px;
  border-radius: 6px;
  border: none;
  padding: 4px;
  background-color: rgb(102, 102, 102);
}
.dinActive {
  background-color: rgb(238, 238, 238);
  height: 23.78px;
}
.dinInactive {
  transition: 0.15s all ease;
}
.dinInactive:hover {
  cursor: pointer;
  background-color: #dadada;
}
.RYBA4topButtonsSmallAndTxtFormatDinamika {
  margin-right: auto;
}
.organTitleDinamika {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 100;
  background-color: rgb(41, 41, 41);
  padding: 4px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
  color: rgb(154, 154, 154);
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
}

.organTitleDinamika:hover {
  background-color: rgb(46, 46, 46);
  cursor: pointer;
  color: rgb(241, 241, 241);
}
.dinamikaNorma {
  margin: 4px;
  border-radius: 6px;
  border: none;
  padding: 5px;
  background-color: rgb(197, 197, 197);
  transition: 0.15s all ease;
}
.dinamikaNorma:hover {
  cursor: pointer;
  background-color: rgb(150, 208, 153);
}
.dinamikaNorma:active {
  background-color: rgb(5, 203, 15);
}
.copiedText {
  padding: 10px;
  position: absolute;
  width: 400px;
  left: 980px;
  font-size: 15px;
  font-weight: 100;
}
.buttonsOrganTitle {
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  color: rgb(205, 205, 205);
}
.buttonsOrganTitleDinamica {
  padding: 4px;
  margin: 0px;
  text-align: center;

  color: rgb(205, 205, 205);
}
.goToNorm {
  border-radius: 6px;
  border: none;
  background-color: #cdccaa;
  padding: 3px;
  margin: 3px;
}
.goToNorm:hover {
  cursor: pointer;
  background-color: #fffdba;
}

.buttonBox {
  min-width: 100px;
}
.anchorForNotes {
  position: relative;
}
.notes {
  position: absolute;
  top: 0px;
  /* border: 0.5px solid red; */
  width: 350px;

  left: -360px;
  border-radius: 6px;
  box-sizing: content-box;
  z-index: 9;
  box-shadow: 0px 5px 10px 2px rgb(0, 0, 0, 0.4);
}
.notesTitle {
  background-color: #1e1e1e;
  text-align: right;
  padding: 5px;
  font-weight: 100;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.notesText {
  width: 100%;
  height: fit-content;
  display: flex;
}
.notesText textarea {
  width: 100%;
  border: none;
  outline: none;
  min-height: 30px;
  background-color: rgb(222, 222, 222);
  padding: 5px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.saveButtonDiv {
  position: relative;
}
.saveButtonDivOK {
  position: absolute;
  top: 0px;
  right: 0px;
}

.svgAndImgContainer {
  position: relative;
}
.svgOver {
  position: absolute;
  top: 0;
  left: 0;
}
.svgHover {
  opacity: 0.3;
  fill: rgb(224, 89, 89);
}
.svgHover:hover {
  opacity: 0.7;
  cursor: pointer;
}
.closeButtonsButton {
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 0.3;
  border-radius: 6px;
  padding: 3px;
}
.closeButtonsButton:hover {
  opacity: 1;
  background-color: #414141;
  cursor: pointer;
}
.imagesContainer {
  position: absolute;
  left: 950px;
  top: -160px;
  width: 420px;
}
.imagesContainer img {
  max-width: 400px;
}
.imageOneSegment {
  /* border: 1px solid red; */
  cursor: pointer;
  border-radius: 6px;
  background-color: #121212;
  padding: 5px;
  margin: 3px;
  font-weight: 100;
}
.imageOneSegment p {
  margin: 3px;
  font-size: 17px;
  color: #8b8b8b;
  font-weight: 500;
}
.overlayImagesContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #0c0c0c;
  border-radius: 6px;
  min-width: 100px;
  align-items: center;
  max-width: 950px;
  padding: 10px;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: fit-content;
  z-index: 1000;
  box-shadow: 0px 0px 1000px 1000px rgb(18, 18, 18, 0.9);
}
.overlayImagesContainer img {
  max-width: 900px;
  margin: 5px;
  border-radius: 6px;
}
.numberOfOption:hover {
  background-color: #cdccaa;
  cursor: pointer;
}
.addButton {
  position: absolute;
  bottom: 0px;
  border-radius: 6px;
  padding: 2px;
}
.addButton:hover {
  background-color: rgba(219, 219, 219, 0.3);
}
.buttonSearchResults {
  width: 800px;
  /* border: 1px solid red; */
}
.foundButton {
  background-color: #0d0d0d;
  padding: 3px;
  margin: 6px;
  border-radius: 6px;
}
.foundButton p {
  margin: 0px;
}
/* contextMenu.css */

.contextMenu {
  position: absolute; /* Absolute positioning */
  background-color: #222; /* Dark background for the container */
  border: 1px solid #444; /* Subtle border */
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Subtle shadow */
  z-index: 1000; /* Ensure it's on top of other elements */
  min-width: 170px; /* Minimum width for the context menu */
}

.contextMenuButton {
  display: flex; /* Use flexbox to align SVG and text */
  align-items: center; /* Vertically center SVG and text */
  width: 100%; /* Button takes up full width */
  border: none; /* Remove button border */
  color: #fff; /* Inherit text color */
  /* padding: 8px 12px; */
  text-align: left; /* Align text to the left */
  cursor: pointer; /* Change cursor to pointer on hover */
  border-radius: 6px;

  background-color: rgb(30, 30, 30, 0) !important;
  font-weight: 100;
  font-size: 14px; /* Adjust font size as needed */
}
.contextMenuButton:hover {
  background-color: #000000 !important;
}
.contextMenuButton svg {
  transition: scale 0.2s ease;
}
.contextMenuButton:hover svg {
  scale: 1.2;
}

.contextMenuButton svg {
  width: 20px; /* Adjust as needed */
  height: 18px; /* Adjust as needed */
  margin-right: 8px; /* Space between SVG and text */
}
