.buttonEditContainer {
  position: absolute;
  width: 800px;

  z-index: 100000;
  left: 0;
  right: 0;
  margin-inline: auto;
  animation: fadeInAndMove 0.4s ease;
}
.boxShadowMaker {
  position: absolute;
  border: 1px solid red;
  z-index: 100;
  width: 20px;
  height: 20px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: -10px -10px 1000px 1000px rgb(0, 0, 0, 0.9);
}
.buttonEditWindow {
  color: rgb(206, 206, 206);
  position: relative;
  z-index: 1000;
  width: 800px;
  height: 100%;
  background-color: rgb(13, 13, 13);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.buttonEditWindow h2 {
  font-weight: 100;
  text-align: center;
  margin: 20px;
}
.textareaAndLabel {
  /* border: 1px solid red; */
  width: 800px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}
.textareaAndLabel p {
  width: 130px;
  font-weight: 100;
  text-align: right;
  vertical-align: middle;
}
.textareaAndLabel textarea {
  outline: none;
  width: 480px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #222;
  color: #f0f0f0;
  resize: vertical;
  margin: 10px;
  resize: none;
  font-weight: 100;
}
.optionsDiv {
  padding-top: 10px;
}
.closeButtonButtonEdit {
  position: absolute;
  top: 0;
  right: 0;
}
.choicesDivButtonEdit {
  /* border: 1px solid red; */
  padding-left: 80px;
  display: flex;
  justify-content: center;
}
.saveButton {
  align-items: center;
}
/* Base styles for the dark theme save button */
.saveButton {
  background-color: #2d3436; /* Dark background */
  color: #ffffff; /* Light text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  padding: 10px 20px; /* Padding for size */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease; /* Smooth transitions */
  outline: none; /* Remove default outline */
  position: relative; /* For pseudo-elements */
  margin: 10px;
  font-weight: 100;
}

/* Hover effect */
.saveButton:hover {
  background-color: #3a4145; /* Slightly lighter background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Active (pressed) state */
.saveButton:active {
  background-color: #4b5357; /* Even lighter background */
  transform: translateY(2px); /* Slight "press" effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow */
}

/* Focus state (for accessibility) */
.saveButton:focus {
  outline-offset: 2px; /* Offset the outline for better visibility */
}

/* Disabled state */
.saveButton:disabled {
  background-color: #444; /* Darker background */
  color: #888; /* Grayed-out text */
  cursor: not-allowed; /* Not-allowed cursor */
  box-shadow: none; /* No shadow */
}
