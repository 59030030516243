.calculators {
  animation: fadeInAndMove 0.5s ease-in-out;
}
.calcList {
  margin: 20px;
  /* border: 1px solid red; */
  text-align: left;
}
.calcList h5 {
  margin-left: 20px;
  margin-top: 18px;
}
.calcList h6 {
  color: rgb(182, 182, 182);
  font-size: 26px;
  text-align: left;
  margin: 10px;
  font-weight: 400;
}
.calcListSegment {
  width: 270px;
  margin: 0px;
  border-radius: 6px;
  padding: 8px;
  background-color: rgb(14, 14, 14);
}
.calcListSegment p {
  margin: 0px;
  font-weight: 100;
  color: rgb(224, 224, 224);
  margin-left: 20px;
  font-size: 20px;
  padding: 0px;
  transition: 0.15s all ease;
}
.calcListSegment p:hover {
  color: #515dba;
  cursor: pointer;
}
.calcIMAGE {
  border-radius: 10px;
  width: 300px;
  margin: 20px;
  box-shadow: 0px 3px 10px grey;
}
.calculatorsContainer {
  display: flex;
  background-color: rgb(31, 31, 31);
  width: 1200px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  text-align: center;
  color: rgb(221, 221, 221);
}
.calculatorsContainer h1 {
  padding: 16px;
}
.calculatorsRow {
  width: 100vw;
  display: inline-block;
  /* display: flex;
  justify-content: space-around; */
}
.calculator {
  display: inline-block;
  /* flex-direction: column;
  justify-content: space-around; */
  width: 350px;
  text-align: center;
  align-items: center;
  height: fit-content;
  background-color: rgb(44, 44, 44);
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  vertical-align: top;
}
.calculator img {
  width: 90%;
}
.name {
  font-size: 22px;
  margin: 5px;
  font-weight: bold;
}
.inputVertical {
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}
.inputVertical input {
  width: 120px;
  height: 30px;
  margin: 5px;
  border-radius: 10px;
  appearance: text;
  background-color: rgb(10, 10, 10);
  color: rgb(235, 235, 235);
  padding: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 100;
  border: none;
}
.results {
  font-size: 32px;
  font-weight: 100;
  border-radius: 10px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  padding: 6px;
  background-color: rgba(95, 75, 75, 0);
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(237, 237, 237);
}
.calculate {
  font-size: 17px;
  font-weight: 100;
  border-radius: 10px;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  padding: 5px;
  background-color: rgb(142, 142, 142);
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.1s ease;
  border: none;
}
.calculate:hover {
  border-radius: 10px;
  background-color: rgb(204, 204, 204);
  cursor: pointer;
}
.calculate:active {
  scale: 0.98;
}
.calculate1 {
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;

  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  padding: 6px;
  background-color: rgb(169, 70, 70);
  margin-top: 10px;
  margin-bottom: 10px;
  transition: all 0.1s ease;
}
.calculate1:hover {
  border-radius: 13px;
  background-color: rgb(145, 156, 175);
}
.calculate1:active {
  scale: 0.98;
}
.legend p {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-weight: 100;
  color: rgb(228, 228, 228);
}
.howtocalc {
  background-color: rgb(62, 62, 62);
  border: 1px solid rgb(43, 43, 43);
  border-radius: 4px;
  color: rgb(184, 184, 184);
  margin: 3px;
}
.howtocalc:hover {
  background-color: rgb(38, 38, 38);
  cursor: pointer;
}
.prostatecalc {
  width: 250px;
}
.spleencalc {
  width: 230px;
}
.pleuraleffusioncalc {
  width: 280px;
}
.subduralhematomacalc {
  width: 240px;
}
