.landingContainer {
  display: flex;
  flex-direction: column;
  /* border: 5px solid red; */
  color: rgb(222, 222, 222);
  justify-content: center;
  align-items: center;
  justify-items: center;
  background: linear-gradient(
    120deg,
    rgba(119, 159, 255, 0.2) 0%,
    rgba(0, 0, 0, 0) 35%
  );
}
.landingSegment {
  display: flex;
  /* align-items: center; */
  /* border: 1px solid red;  */
}
.landingh1 {
  font-size: 3rem;
  margin: 10px;
}
.landingspan1 {
  color: #515dba;
}
.landingimg {
  border-radius: 6px;
  position: absolute;
  box-shadow: -3px 0px 47px 20px rgba(0, 0, 0, 0.5);
}
.landingText {
  font-size: 2rem;
  font-weight: 100;
}
/* .tryRybaButton {
  padding: 10px;
  border-radius: 12px;
  font-size: 24px;

  background-color: #515dba;
  border: none;
  width: 300px;
  font-weight: 100;
} */
.tryRybaButton {
  font: inherit;
  background-color: #f0f0f0;
  border: 0;
  color: #242424;
  border-radius: 0.5em;
  font-size: 1.35rem;
  padding: 0.375em 1em;
  font-weight: 600;
  text-shadow: 0 0.0625em 0 #fff;
  box-shadow: inset 0 0.0625em 0 0 #f4f4f4, 0 0.0625em 0 0 #efefef,
    0 0.125em 0 0 #ececec, 0 0.25em 0 0 #e0e0e0, 0 0.3125em 0 0 #dedede,
    0 0.375em 0 0 #dcdcdc, 0 0.425em 0 0 #cacaca, 0 0.425em 0.5em 0 #cecece;
  transition: 0.15s ease;
  cursor: pointer;
}
.tryRybaButton:active {
  translate: 0 0.225em;
  box-shadow: inset 0 0.03em 0 0 #f4f4f4, 0 0.03em 0 0 #efefef,
    0 0.0625em 0 0 #ececec, 0 0.125em 0 0 #e0e0e0, 0 0.125em 0 0 #dedede,
    0 0.2em 0 0 #dcdcdc, 0 0.225em 0 0 #cacaca, 0 0.225em 0.375em 0 #cecece;
}
.imageCommentLanding {
  margin: 30px;
  font-size: 1.5rem;
  font-weight: 100;
}
