.modalityrybaList {
  text-align: left;
  margin: 5px;
  padding: 10px;
  width: 170px;
  background: rgb(23, 23, 23);
  border-radius: 10px;
  border: none;
  color: #dddddd;
  margin-top: 10px;
  box-shadow: -3px 22px 27px -29px rgba(81, 93, 186, 0.2);
  font-size: 1rem;
  font-weight: 100;
  font-family: sans-serif;
  animation: fadeIn 0.6s;
  transition: all 0.15s ease-in-out;
}
.modalityrybaList p {
  font-size: 1.2rem;
  font: large;
  font-weight: 100;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  color: rgb(213, 213, 213);
}
.modalityrybaList button {
  border-radius: 10px;
  margin-top: 20px;
}
.modalityrybaList a {
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}
.modalityrybaList a:link {
  color: #e5e5e5;
}
.modalityrybaList a:visited {
  color: #e5e5e5;
}
.modalityrybaList a:hover {
  color: #515dba;
  cursor: pointer;
}
.modalityrybaList a:active {
  color: rgb(165, 110, 8);
  cursor: pointer;
}
.modalityrybaList li {
  list-style-type: none;
  padding: 1px;
}
.modalityrybaList hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(60, 60, 60, 1),
    rgba(140, 140, 140, 0.4),
    rgba(60, 60, 60, 0.2)
  );
}
/* ///////////////////////////////////////*/
.rybaRowShapka {
  display: flex;
  font-size: 17px;
  min-height: 40px;
  margin-top: 15px;
  margin-bottom: 10px;
  color: darkgrey;
}
.rybaRowTitleShapka {
  width: 250px;
}
.rybaRowProtocolShapka {
  width: 215px;
}
.rybaRowCreatedAtShapka {
  width: 150px;
  margin-left: -9px;
}
.rybaRow {
  display: flex;
  min-height: 30px;
}
.rybaRowTitle {
  width: 250px;
}
.rybaRowProtocol {
  width: 200px;
  padding-right: 15px;
  color: grey;
  font-size: 13px;
  margin-bottom: 5px;
}
.rybaRowCreatedAt {
  width: 150px;
  color: grey;
  font-size: 14px;
  margin-left: 10px;
}
.rybaRowButtonEdit {
  padding: 0px;
  margin-left: 10px;
  color: grey;
  font-size: 15px;
}
.rybaRowButtonDelete {
  padding: 0px;
  margin-left: 10px;
  color: grey;
  font-size: 15px;
}
.rybaRowButtonEdit:hover {
  cursor: pointer;
  color: rgb(228, 228, 186);
}
.rybaRowButtonDelete:hover {
  cursor: pointer;
  color: rgb(244, 85, 85);
}
.modalityrybaList2 {
  text-align: left;
  margin: 10px;
  padding: 15px;
  /* width: 1100px; */
  height: fit-content;
  min-height: 100%;
  background: rgb(23, 23, 23);
  border-radius: 10px;
  border: none;
  color: #dddddd;
  margin-top: 10px;
  box-shadow: -3px 32px 47px -29px rgba(81, 93, 186, 0.3);
  font-size: 1.1rem;
  font-weight: 100;
  font-family: sans-serif;
  animation: fadeIn 0.6s;
  transition: all 0.15s ease-in-out;
}
.modalityrybaList2 p {
  font-size: 2rem;
  font: large;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  color: rgb(211, 211, 211);
}
.modalityrybaList2 button {
  border-radius: 10px;
  margin-top: 20px;
}
.modalityrybaList2 a {
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}
.modalityrybaList2 a:link {
  color: #e5e5e5;
}
.modalityrybaList2 a:visited {
  color: #e5e5e5;
}
.modalityrybaList2 a:hover {
  color: #7e37ab;
  cursor: pointer;
}
.modalityrybaList2 a:active {
  color: rgb(165, 110, 8);
  cursor: pointer;
}
.modalityrybaList2 li {
  list-style-type: none;
  padding: 1px;
}
.modalityrybaList2 hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(60, 60, 60, 0),
    rgba(140, 140, 140, 0.75),
    rgba(60, 60, 60, 0)
  );
}

/* ///////////////////////////////////////*/

.rybaListContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rybaListSubcontainer1 {
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.rybaListSubcontainer2 {
  display: flex;
  flex-direction: column;
}
.modalityIcon {
  width: fit-content;
  height: fit-content;
}

h5 {
  font-weight: normal;
  padding-bottom: 5px;
  margin: 0px;
}
.rybovNet {
  color: grey;
  font-size: 14px;
}

.pageSelectDiv {
  padding: 10px;
  color: rgb(255, 245, 226);
  margin-left: auto;
  margin-right: auto;
}
.pageSelect {
  border: 0.5px solid white;
  border-radius: 70px;
  margin: 4px;
  padding: 4px;
}
.pageSelect:hover {
  border: 0.5px solid white;
  background: #515dba;
  color: black;
  cursor: pointer;
  border-radius: 70px;
  margin: 4px;
  padding: 4px;
}
.pageSelectButton {
  border-radius: 70px;
}
.protocolsList {
  color: rgb(192, 192, 192);
  display: flex;
  flex-direction: column;
  font-weight: 100;
}
.protocolDiv {
  display: flex;
  flex-direction: row;
  border: 0.5px solid rgb(112, 112, 112);
  border-radius: 3px;
}
.protocol {
  padding: 3px;
  border-right: 0.5px solid rgb(112, 112, 112);
  width: 300px;
}
.protocolRybas {
  padding: 3px;
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  max-width: 350px;
}
.protocolTitleSpan {
  color: rgb(188, 188, 188);
  text-decoration: none;
}
.protocolTitleSpan:hover {
  color: rgb(226, 226, 226);
}
.standardRybaTitle {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: rgb(192, 192, 192) !important;
}
.standardRybaTitle:hover {
  color: rgb(242, 242, 242) !important;
  cursor: pointer;
}
