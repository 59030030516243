/* Основные стили для затемненного фона */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Стили для контента модального окна */
.modal-content {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeInRyba 0.3s ease-in-out;
}

/* Анимация появления */
@keyframes fadeInRyba {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Стили для текста */
.modal-content h2 {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 20px;
}

/* Стили для кнопок */
.modal-content button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Основная кнопка */
.primary-button {
  background-color: #4caf50;
  color: white;
}

.primary-button:hover {
  background-color: #45a049;
  transform: scale(1.02);
}

/* Вторичная кнопка */
.secondary-button {
  background-color: #333333;
  color: #cccccc;
}

.secondary-button:hover {
  background-color: #444444;
  transform: scale(1.02);
}
