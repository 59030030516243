.rooDivEdit {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.rooDivEdit textarea {
  outline: none;
}

.topButtons {
  position: relative;
  display: flex;
  width: 1450px;
  margin-left: auto;
  margin-right: auto;
  background: rgb(65, 65, 65, 0.4);
  /* background-color: #414141; */
  border-radius: 10px;
  padding: 10px;
}
.titleEditing {
  display: flex;
  flex-direction: column;
}

.titleEditing textarea {
  min-height: 40px;
  min-width: 400px;
  background-color: rgb(24, 24, 24);
  color: rgb(236, 236, 236);
  font-size: 28px;
  font-weight: 500;
  padding: 5px;
  text-align: center;
  border-radius: 10px;
  outline: none;
  border: 1px solid transparent;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
}
.titleEditing textarea:hover {
  background-color: rgb(16, 16, 16);
}

.rybaRedactorNew {
  width: 260px;
  height: fit-content;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px;
  padding-top: 15px;
  transition: all 0.15s ease-in-out;
  /* background: transparent; */
  background: rgb(65, 65, 65, 0.4);
  /* background: rgb(65, 65, 65); */
  color: #dddddd;
  border-radius: 6px;
  animation: fadeIn 0.6s;
  overflow-y: scroll;
  scrollbar-color: rgb(31, 31, 31, 0) rgb(69, 69, 69, 0);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-x: hidden;
  scrollbar-width: none;
  /* border: 0.5px solid grey; */
}
.organRedactor {
  background: transparent;
  border-radius: 6px;
  width: 1180px;
  margin: 10px;
  padding: 0px;
  /* height: 80vh; */
  overflow-y: scroll;
  scrollbar-color: rgb(31, 31, 31) rgb(69, 69, 69);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-x: hidden;
  scrollbar-width: none;
}
.topButtonsRedactor {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  text-align: center;
}
.saveButtonsDiv {
  margin-left: 10px;
  width: 270px;
  margin-top: auto;
  margin-bottom: auto;
}
.bigSaveButton {
  background-color: rgb(120, 213, 120);
  width: 250px;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 20px;
  border-radius: 10px;
  /* font-weight: 900; */
  transition: 0.2s all ease;

  border: 1px solid transparent;
}
.bigSaveButton:hover {
  background-color: rgb(39, 160, 39);
  cursor: pointer;
}
.bigNOTSaveButton {
  background-color: rgb(139, 139, 139);
  width: 250px;
  margin-top: 10px;
  font-size: 15px;
  border-radius: 10px;
  /* font-weight: 300; */
  transition: 0.2s all ease;
  margin-top: auto;
  margin-bottom: auto;
  border: 1px solid transparent;
}
.bigNOTSaveButton:hover {
  background-color: rgb(184, 141, 141);
  cursor: pointer;
}
.rybaModalityProtocolShareDiv {
  font-weight: 100;
  color: #eaeaea;
  width: 400px;
  display: flex;
}
.rybaModalityProtocolShareDiv div {
  text-align: center;
}
.protocolSelectSELECT {
  width: 200px;
  min-height: 30px;
  font-size: 13px;
  color: rgb(222, 222, 222);
  background-color: black;
  margin: 3px;
  border-radius: 6px;
  text-align: center;
}
.shareRybaP {
  color: #dddddd;
  width: 350px;
  padding: 0px;
  margin: 0px;
}
.shareRybaButton {
  background-color: rgb(164, 164, 164);
  width: 200px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  border-radius: 10px;
  font-weight: 100;
  transition: 0.2s all ease;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
}
.shareRybaButton:hover {
  background-color: red;
  cursor: pointer;
}
.RYBA4segmentsContainerEdit {
  min-height: 950px;
  text-align: center;
}
.RYBA4segmentsContainerEdit:hover .organEditButtons {
  opacity: 1;
}
.RYBA4segmentsContainerEditRIGHT {
  text-align: center;
  padding-top: 15px;
}
.RYBA4segmentsContainerEditRIGHT:hover .organEditButtons {
  opacity: 1;
}
.organEdit {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  position: relative;
  /* margin-left: auto; */
  margin-right: auto;
}
.organEditButtons {
  position: absolute;
  left: -10px;
  top: -3%;
  opacity: 0;
  transition: all 0.15s ease;
  background-color: #323232;
  border-radius: 6px;
}
.editButtons {
  width: 250px;
  height: 40px;
  font-size: 15px;
  margin: 4px;
  margin-left: 60%;
  border-radius: 10px;
  background-color: bisque;
}
.organSegmentsEdit {
  width: 98%;
}
.organTitleEdit {
  /* width: 200px; */
  width: 100%;

  background-color: transparent;
  padding: 0px;

  color: rgb(229, 229, 229);
  /* width: 250px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 10px;
  font-weight: 100;
}

.organTitleEdit button {
  opacity: 0;
  transition: 0.15s all ease;
}
.organTitleEdit:hover button {
  opacity: 1;
}
.organTitleEdit textarea:hover {
  cursor: pointer;
}
.organTitleTextarea {
  width: 90%;
  /* min-height: 60%; */
  text-align: left;
  font-size: 21px;
  background: rgb(23, 23, 23);
  color: rgb(218, 218, 218);
  outline: none;
  border: 0.5px solid transparent;
  border-radius: 6px;
  padding: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
.organTitleTextarea:hover {
  background-color: #c2c2c2;
}
.organTitleTextareaNOTEDITED {
  width: 90%;
  /* min-height: 60%; */
  text-align: left;
  font-size: 21px;
  background: transparent;
  color: rgb(218, 218, 218);
  outline: none;
  border: 0.5px solid transparent;
  border-radius: 6px;
  padding: 5px;
  margin-top: auto;
  margin-bottom: auto;
  transition: all 0.15s ease;
}
.organTitleTextareaNOTEDITED:hover {
  background: rgba(65, 65, 65, 0.7);
}
.sgmntEdit {
  background-color: transparent;
  /* background-color: #414141; */
  display: flex;
  position: relative;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  padding: 5px;
  margin: 0px;
  margin-bottom: 20px;
  width: 100%;
}

/* .mainSegment {
  display: flex;
  border: 2px solid red;
} */
.segmentTitleDivEdit {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  /* background-color: rgb(45, 45, 45); */
  padding: 0px;
  color: rgb(180, 180, 180);
  width: 100%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-bottom: 10px;
}
.segmentEditButtonsContainer {
  text-align: center;
  display: flex;
  position: absolute;
  right: 5px;
  /* background-color: #222222; */
  width: 154px;
  margin-right: 4px;
  border-radius: 10px;
  margin-left: 2px;
  margin-top: 2px;
}
.segmentTitleDivEdit .segmentHover {
  opacity: 0;
  transition: 0.15s all ease;
}
.sgmntEdit:hover .segmentHover {
  opacity: 1;
}
.segTitleText {
  width: 162px;
  text-align: left;
  font-size: 20px;
  font-weight: 100;
  background-color: rgb(23, 23, 23, 0);
  border: 0px solid black;
  border-radius: 10px;
  padding: 7px;
  color: rgb(218, 218, 218);
  margin-right: 5px;
}
.segTitleText:hover {
  background-color: rgb(19, 19, 19);
}

.segmentTextEdit {
  /* border: 3px solid blue; */
  width: 100%;
  /* margin: 3px; */
  display: flex;
  flex-direction: column;
  color: rgb(190, 201, 238);
}
.segmentTextTextareaEdit {
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 100;
  width: 100%;
  padding: 6px;
  min-height: 100%;
  border: 0px;
  outline: none;
  background: #cbcbcb;
  border: 0px solid rgb(158, 158, 158);
  border-radius: 10px;
  color: #171717;

  padding-right: 200px;
}
.segmentTextTextareaEdit:hover {
  background-color: #c9c9c9;
}
.segmentTextTextareaEdit:focus {
  background-color: #dfdfdf;
}
.segmentButtonsEdit {
  /* border: 1px solid orange; */
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 6px;
}

.addButtonsDiv {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
.segmentButtonEdit {
  min-width: 40px;
  border-radius: 10px;
  border: none;
  background-color: #d8d7bd;
  transition: all 0.15s ease;
}
.segmentButtonsEdit button {
  margin: 1px;
  padding: 1px;
  font-size: 15px;
  width: 30px;
  height: fit-content;
  text-wrap: wrap;
}
.segmentButtonEdit:hover {
  background-color: #ffe88b;
  cursor: pointer;
}
.buttonEdit {
  display: flex;
  margin-bottom: 0px;
}
.buttonButtons {
  display: flex;
  width: 305px;
}
.editDiv2 {
  background-color: rgb(21, 21, 21, 0);
  padding: 4px;
  /* height: 32px; */
  width: 40px;
  position: absolute;
  left: 0px;
  border-radius: 10px;
  border: 1px solid rgb(173, 216, 230, 0);
  display: flex;
  flex-direction: row;
}

.notEditedSegment {
  position: relative;
  margin: 0px;
  /* padding: 5px; */
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-size: 19px;
}
/* .notEditedSegment p {
  margin: 0px;
}
.notEditedSegment:hover {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(46, 46, 46);
  border-radius: 6px;
  transition: all 0.2s ease;
} */
.clickToEdit {
  position: absolute;

  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}
.clickToEdit:hover {
  opacity: 1;
  color: black;
  cursor: pointer;
}
.addNewSegment {
  width: 30%;
  height: 35px;
  font-size: 17px;
  margin-left: 6px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: rgb(199, 195, 190);
}
.addNewOrgan {
  width: 240px;
  height: fit-content;
  min-height: 35px;
  font-size: 17px;
  margin-left: 6px;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
  border: 0.5px solid grey;
  color: rgb(156, 156, 156);
  background-color: rgb(156, 156, 156, 0);
}
.addNewOrgan:hover {
  background-color: rgb(229, 226, 221);
  cursor: pointer;
  color: rgb(57, 57, 57);
}
.svgButton {
  background-color: rgb(22, 22, 22);
  border-radius: 6px;
  border: 0px solid black;
  margin: 2px;
  width: 32px;
  padding: 0px;
}
.svgButton:hover {
  cursor: pointer;
  background-color: black;
}
.optionsandradioseditdiv {
  border-radius: 6px;
  background-color: #000000;
  height: fit-content;
  padding: 2px;
}
.optionsandradioseditdiv button {
  margin-left: 2px;
  background-color: #252525;
}
.buttonContentDiv {
  padding: 4px;
  color: rgb(194, 194, 194);
  font-size: 14px;
  /* border: 1px solid green; */
  border-radius: 10px;
  margin: 3px;
  transition: all 0.2s ease;
  /* width: 100%; */
}
.buttonAndContent {
  display: flex;
  flex-direction: row;

  border-radius: 10px;
  background-color: #252525;
  margin: 3px;
  margin-bottom: 7px;
  margin-left: 50px;
  margin-right: 0px;
}

.showButtons {
  margin-right: 10%;
  padding: 6px;
  color: #171717;
  border: none;
  background-color: #b6b6b6;
  border-radius: 6px;
  transition: all 0.15s ease;
}
.showButtons:hover {
  background-color: #252525;
  color: #c9c9c9;
  cursor: pointer;
}
.finalOrganText {
  width: 1124px;
  margin: 10px;
  margin-left: 10px;
  margin-right: auto;
  padding: 5px;
  color: rgb(200, 200, 200);
  /* border: 1px solid rgb(200, 200, 200); */
  border-radius: 10px;
  background-color: transparent;
  font-weight: 100;
  font-size: 20px;
  background: rgb(65, 65, 65, 0);
  /* background: rgb(65, 65, 65, 0.4); */
}
.finalOrganText p {
  padding: 9px;
  margin: 0px;
}
.finalOrganText:hover {
  cursor: default;
}
/*////////////   BUTTONS REDACTOR DESIGNS    ////////////*/
.buttonEditingSegment {
  display: flex;
}
.buttonEditingSegment textarea {
  border-radius: 6px;
  background-color: #bababa;
  border: 0px solid black;
  margin: 1px;
  padding: 3px;
}
.buttonTITLE {
  width: 170px;
  color: grey;
}
.buttonTITLE textarea {
  width: 97%;
}
.buttonCONTENT {
  width: 740px;
  color: grey;
}
.buttonCONTENT textarea {
  width: 97%;
}
.buttonZAKL {
  width: 260px;
  color: grey;
}
.buttonZAKL textarea {
  color: black;
  width: 97%;
}
.buttonZAKL textarea:placeholder-shown {
  background-color: #414141;
}
/*/ ///////// SELECT AND RADIO EDITING //////////////*/
.buttonTITLEradioORselect {
  vertical-align: middle;
  text-align: left;
  align-items: center;
  padding: 3px;
}
.buttonTITLEradioORselect label {
  position: absolute;
  margin-left: 200px;
}

.buttonTITLEradioORselect textarea {
  width: 450px;
  border-radius: 6px;
  font-size: 19px;
  font-weight: 400;
  background-color: transparent;
  color: rgb(219, 219, 219);
  text-align: left;
  padding: 3px;
  border: 0px solid black;
  margin: 0px;
  transition: 0.15s all ease;
  padding: 3px;
  min-height: 100%;
}
.buttonTITLEradioORselect textarea:hover {
  background-color: rgb(16, 16, 16);
  color: rgb(226, 226, 226);
}
.buttonTITLEradioORselect textarea:focus {
  background-color: rgb(16, 16, 16);
  color: rgb(226, 226, 226);
}
.buttonEditingSegmentRADIOorSELECT {
  display: flex;
  height: fit-content;
}
.buttonEditingSegmentRADIOorSELECT textarea {
  border-radius: 6px;
  background-color: #bababa;
  border: 0px solid black;
  margin: 1px;
  min-height: 9%;
}
.buttonTITLE2 {
  width: 140px;
  color: grey;
  /* margin-top: auto; */
  margin-bottom: auto;
  height: 100%;
}
.buttonTITLE2 textarea {
  width: 97%;
  text-align: center;
  background-color: #8f8f8f;
  min-height: 100%;
}
.buttonCONTENT2 {
  width: 510px;
  color: grey;
}
.buttonCONTENT2 textarea {
  width: 97%;
  min-height: 75%;
}
.buttonZAKL2 {
  width: 160px;
  color: grey;
  margin-right: 5px;
}
.buttonZAKL2 textarea {
  width: 97%;
}
.buttonZAKL2 textarea:placeholder-shown {
  background-color: #414141;
}
.constructorSelect {
  width: 200px;
  height: 25px;
  text-align: center;
  font-size: 15px;
  color: #e7e7e7;
  background-color: #000000;
  border-radius: 6px;
  outline: none;
}
.whiteBorder {
  border: 0.5px solid rgb(227, 227, 227);
  border-radius: 6px;
  background-color: #cbcbcb;
  color: #171717;
}
.whiteBorder:hover {
  border: 0.5px solid rgb(227, 227, 227);
  border-radius: 6px;
  background-color: #dddddd;
  color: #171717;
}
.contextMenu .redOnHover:hover {
  background-color: rgb(142, 29, 48);
  background: rgb(176, 81, 97);
}
.notWhiteBorder {
  background: #414141;
  border: 1px solid red;
}
.notWhiteBorder:hover {
  background: #909090;
}
.choices {
  background-color: #121212;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 100;
  text-align: left;
  padding: 4px;
  margin: 4px;
}
.choicesOption {
  margin: 1px;
  padding: 2px;
  border-radius: 6px;
  background-color: transparent;
  transition: 0.15s all ease;
  color: grey;
  border: none;
}
.choicesOption:hover {
  background-color: #767676;
  color: black;
}
.choicesOption:focus {
  background-color: #b6b6b6;
  color: black;
}
.choicesTextarea {
  background-color: #c9c9c9;
  border-radius: 6px;
  padding: 4px;
  margin-top: 5px;
  width: 300px;
}
.choicesButton {
  background-color: #2f2f2f;
  padding: 4px;
  border-radius: 6px;
  margin-left: 5px;
  width: 25px;
  opacity: 1;
}
.choicesButton:hover {
  cursor: pointer;
  background-color: #2e2e2e;
}
.choiceDeleteSpan {
  margin: 5px;
}
.choiceDeleteSpan:hover {
  cursor: pointer;
}
.choicesSegment {
  width: 100%;
  margin-bottom: 4px;
  padding: 7px;
  padding-bottom: 7px;
  border-radius: 6px;
  border: 0.5px solid rgb(59, 59, 59);
}
.forSummary {
  display: flex;
  align-items: center;
}
.pointer:hover {
  cursor: pointer;
}
.optionsTextPreview {
  margin: 4px;
}
.numberOfOption {
  /* border: 1px solid red; */
  /* border: 1px solid red; */
  /* border: 1px solid red; */
  /* border: 1px solid red; */
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 6px;
  background-color: #bcbcbc;
  color: black;
}
.choicesSegmentNumber {
  width: 30px;
  font-size: 24px;
}
.choicesDelete {
  width: 24px;
  height: 24px;
  padding: 0px;
  margin: 5px;
  border-radius: 6px;
  scale: 1;
}

.choicesDelete:hover {
  background-color: #323232;
  cursor: pointer;
}
.inputPromptOptions button {
  background: #2d2d2d !important;
  border: 0.5px solid rgb(104, 104, 104) !important;
}

.inputPromptOptions button:hover {
  background: #242424 !important;
  cursor: pointer;
}
.add {
  opacity: 0;
  transition: 0.15s all ease;
}
.choicesSegmentContainer {
  display: flex;
}
.choicesSegmentContainer:hover .add {
  opacity: 1;
}
.imagesUploadDiv {
  color: rgb(185, 185, 185);
  margin: 5px;
  padding: 2px;
  width: 250px;
}
.imageInput {
  border: 2px dotted rgb(169, 169, 169);
  margin: 4px;
  height: 50px;
  border-radius: 6px;
  padding: 3px;
  width: 230px;
  background-color: rgb(54, 54, 54);
  /* width: 50px; */
}
.buttonImageContainer {
  position: relative;
}
.buttonImageDelete {
  position: absolute;
  right: 3px;
  top: 3px;
}
.buttonImageDelete:hover {
  cursor: pointer;
}
.imagesContainter {
  display: flex;
  flex-direction: row;
}
.imagesContainter img {
  border-radius: 6px;
  margin: 2px;
  max-width: 100px;
  max-height: 100px;
}
.ImagesToTheRightContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #0c0c0c;
  border-radius: 6px;

  align-items: center;
  min-height: 900px;
  height: fit-content;
  padding: 10px;
  left: 0;
  right: 0;
  margin-inline: auto;

  width: 950px;
  z-index: 1000;
  box-shadow: 0px 0px 1000px 1000px rgb(18, 18, 18, 0.9);
}
.ImagesToTheRightContainer img {
  max-width: 900px;
  margin: 5px;
  border-radius: 6px;
}
.shporaSegment {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  background-color: #272727;
  width: 880px;
  margin-bottom: 15px;
  padding: 6px;
  color: rgb(235, 235, 235);
}
.shporaTitle {
  width: 300px;
  border-radius: 6px;
  margin: 3px;
  padding: 3px;
  font-size: 18px;
  min-height: fit-content;
  background-color: #cdcdcd;
}
.shporaText {
  /* width: 300px; */
  border-radius: 6px;
  margin: 3px;
  padding: 3px;
  background-color: #cdcdcd;
}
/* contextMenu.css */
.menuHover:hover {
  color: grey;
}
.choicesMenuSegment {
  background-color: #000000;
  padding: 3px;
  padding-top: 5px;
  padding-bottom: 0px;
  border-radius: 6px;
  margin-left: 3px;
  height: 31px;
  width: 33px;
  transition: 0.15s all ease;
}
.choicesMenuSegment:hover {
  cursor: pointer;
  background-color: #101010;
}
.choicesMenuSegment svg {
  transition: 0.15s all ease;
}
.choicesMenuSegment:hover svg {
  scale: 1.1;
}
/*//////////////////  CREATE BUTTON BUTTON   ////////////////*/
.buttonCreateButton {
  all: unset;
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  will-change: box-shadow, transform;
  background: #ffe8a9;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #946b1e;
  height: 48px;
  padding: 0 32px;
  font-size: 22px;
  border-radius: 6px;
  color: #36395a;
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  padding: 2px;
  padding-bottom: 5px;
  margin: 2px;
  height: 33px;

  font-size: 14px;
}

.buttonCreateButton:hover {
  box-shadow: 0px 4px 8px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
  transform: translateY(-2px);
}
.buttonCreateButton:active {
  box-shadow: inset 0px 3px 7px #d6d6e7;
  transform: translateY(2px);
}

/* CSS */
.button-1 {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-1-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.button-1-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: rgb(154, 102, 38);
  /* background: linear-gradient(
    to left,
   rgb(250, 143, 9),
    #a30036 8%,
    #a30036 92%,
    #52001b 100%
  ); */
}

.button-1-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(48, 48, 48);
  background: #ffbd03;
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

@media (min-width: 768px) {
  .button-1-front {
    font-size: 1.35rem;
    padding: 12px 42px;
  }
}

.button-1:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-1:hover .button-1-front {
  transform: translateY(-6px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-1:active .button-1-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-1:hover .button-1-shadow {
  transform: translateY(4px);
  transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}

.button-1:active .button-1-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-1:focus:not(:focus-visible) {
  outline: none;
}
.searchButtonDiv {
  width: fit-content;
  height: fit-content;
  text-align: center;
  margin: auto;
  padding: 0px;
  margin-top: 7px;
  background-color: transparent;
  border-radius: 25px;
  transition: all 0.2s ease;
  opacity: 0.6;
}
.searchButtonDiv:hover {
  opacity: 0.85;
}
.searchButtonDiv svg {
  transition: all 0.2s ease;
}
.searchButtonDiv:hover svg {
  scale: 1.1;
  fill: #ededed;
}
.applyToAllButtons {
  position: absolute;
  width: 180px;
  background-color: orange;
  right: 15px;
  border-radius: 6px;
  border: none;
}
