/* Container */
.textarea-anim-container {
  position: relative;
  margin: 20px;
  font-family: Arial, sans-serif;
}

/* Main textarea */
.textarea-anim-main {
  width: 300px;
  height: 150px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease;
  outline: none;
  resize: none;
}

/* Character count */
.textarea-char-count {
  position: absolute;
  left: -90px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #666;
  min-width: 30px;
  text-align: right;
}

/* Base indicator style */
.textarea-added-indicator {
  position: absolute;
  left: -240px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  text-align: right;
  min-width: 30px;
  color: #ffcc00; /* Green color */
  /* color: #4caf50; */
}

/* Typing animation */
.textarea-type-indicator {
  animation: textareaFlyFade 1s forwards;
}

/* Button animation */
.textarea-button-indicator {
  animation: textareaFlyFade 1.1s forwards;
}

@keyframes textareaFlyFade {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  70% {
    opacity: 0.8;
    transform: translateY(-20px) scale(1.3);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px) scale(1.5);
  }
}

/* Textarea highlight for typing */
.textarea-type-highlight {
  animation: textareaPulseType 1.1s ease-out;
}

/* Textarea highlight for button */
.textarea-button-highlight {
  animation: textareaPulseButton 1.1s ease-out;
}

@keyframes textareaPulseType {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 165, 0, 0.4); /* Saturated orange */
  }
  70% {
    box-shadow: 0 0 0 8px rgba(255, 165, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 165, 0, 0);
  }
}

@keyframes textareaPulseButton {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 165, 0, 0.9); /* Saturated orange */
  }
  70% {
    box-shadow: 0 0 0 12px rgba(255, 165, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 165, 0, 0);
  }
}

/* Add button */
.textarea-add-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.textarea-add-btn:hover {
  background-color: #45a049;
}
