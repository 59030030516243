/* Container for the status bar */
.status-bar-container {
  width: 100%;
  background-color: #e0e0e0; /* Background color of the bar container */
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 20px;
}

/* The actual progress bar */
.status-bar {
  height: 100%;
  background-color: #4caf50; /* Green color for progress */
  border-radius: 8px;
  transition: width 0.3s ease; /* Smooth transition for width changes */
}

/* Label to display the percentage */
.status-bar-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #000 !important; /* Black color for text */
  font-weight: bold;
}
