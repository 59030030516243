/* Navbar Container */
.navbar {
  background-color: #1a1a1a; /* Dark background */
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  /* display: flex; */
  padding-top: 40px;
  align-items: center;
}

/* Button and Trigger */
.navbar-menu-trigger {
  position: relative;
}

.navbar-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 100;
}

.navbar-button:hover {
  background-color: #374151; /* Darker background on hover */
}

.navbar-icon {
  width: 1rem;
  height: 1rem;
  transition: transform 0.3s ease;
}

.navbar-icon-rotate {
  transform: rotate(180deg);
}

/* Dropdown Menu */
.navbar-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 16rem;
  background-color: #2d2d2d; /* Darker dropdown background */
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: fadeInMenu 0.3s ease;
}

@keyframes fadeInMenu {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.navbar-dropdown-items {
  padding: 0.5rem 0;
}

.navbar-dropdown-item {
  display: block;
  padding: 0.75rem 3.3rem;
  color: white;
  font-size: 0.875rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
  position: relative;
}
.navbar-dropdown-item svg {
  position: absolute;
  left: 14px;
  top: 8px;
}

.navbar-dropdown-item:hover {
  background-color: #374151; /* Darker background on hover */
}
.navbar-dropdown-item svg {
  transition: scale 0.2s ease;
}
.navbar-dropdown-item:hover svg {
  scale: 1.2;
}

.navbar-dropdown-divider {
  border-top: 1px solid #444; /* Divider line */
}

.navbar-dropdown-settings {
  color: #60a5fa; /* Blue accent for settings */
}
.counterOfNotifications {
  background-color: #1d1d1d;
  color: grey;
  font-size: 12px;
  padding: 2px 3px;
  position: absolute;
  top: -5px;
  right: -5px;
}
.counterOfNotificationsDiv:hover {
  background-color: #121212;
  cursor: pointer;
}
.notificationsList {
  opacity: 1;
  position: absolute;
  background-color: #121212;
  color: rgb(200, 200, 200);
  width: 270px;
  font-size: 13px;
  top: 50px;
  padding: 10px;
  padding-top: 0px;
  border: 0.5px solid rgb(55, 55, 55);
}
.notificationsList p {
  padding: 7px;
  padding-bottom: 1px;
  margin: 0px;
}
.closeButton button {
  background-color: #393939;
  border: none;
  border-radius: 6px;
}
