.buttonMenu {
  /* border: 1px solid red; */
  background-color: rgb(42, 42, 42);
  color: rgb(191, 191, 191);
  font-weight: 100;
  border-radius: 6px;
  font-size: 15px;
  /* padding: 4px; */
}
.organFromButtonMenu {
  padding: 4px;
}
.organFromButtonMenu:hover {
  background-color: black;
  cursor: pointer;
}
.segmentsMenu {
  /* border: 1px solid red; */
  background-color: rgb(53, 53, 53);
  color: rgb(191, 191, 191);
  font-weight: 100;
  border-radius: 6px;
  width: 400px;
  box-shadow: 7px 5px 15px 0px rgb(0, 0, 0, 0.5);
}
