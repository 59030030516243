.loginButton {
  width: 50%;
  margin: 7px;
  padding: 6px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  font-weight: 100;
  border-radius: 6px;
  border: none;
  background-color: rgb(184, 184, 184);
}
.loginButton:hover {
  cursor: pointer;
  background-color: rgb(223, 223, 223);
}
.forgotPasswordButton {
  font-size: 15px;
  color: grey;
}
.forgotPasswordButton:hover {
  cursor: pointer;
  color: rgb(223, 223, 223);
}
