.pageA4 {
  background-color: grey;
  white-space: pre-wrap;
  width: 90%;
  padding: 5%;
  color: rgb(0, 0, 0);
}
.pageA4 h1 {
  text-align: center;
  margin-top: 100px;
}
.printHeader {
  text-align: center;
}
.doctorName {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}
.doctorName span {
  text-align: right;
}
.date {
  margin-top: 20px;
}
