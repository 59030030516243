.close-button {
  --line-thickness: 2px; /* Thickness of the X lines */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  background-color: transparent;
  border: none;
  border-radius: 30%; /* Rounded borders */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  outline: none;
  position: relative; /* Ensure proper positioning context for the icon */
}

.close-button:hover {
  background-color: var(--hover-color);
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.close-button:focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2); /* Focus state for accessibility */
}

.x-icon {
  display: block;
  position: relative;
  width: calc(var(--size) * 0.6); /* Size of the X icon */
  height: calc(var(--size) * 0.6);
  margin: auto; /* Center the icon within the button */
}

.x-icon::before,
.x-icon::after {
  content: "";
  position: absolute;
  top: 50%; /* Vertically center the lines */
  left: 50%; /* Horizontally center the lines */
  width: calc(var(--size) * 0.4); /* Adjust line length */
  height: var(--line-thickness);
  background-color: var(--color);
  border-radius: var(--line-thickness); /* Rounded edges for the lines */
  transform-origin: center; /* Ensure rotation happens from the center */
}

.x-icon::before {
  transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate */
}

.x-icon::after {
  transform: translate(-50%, -50%) rotate(-45deg); /* Center and rotate */
}

.close-button:hover .x-icon::before,
.close-button:hover .x-icon::after {
  background-color: white; /* Change line color on hover */
}
