.drag-drop-list {
  background-color: #1a1a1a;
  padding: 1rem;
  padding-right: 3rem;
  border-radius: 8px;
  width: 600px;
  max-width: 900px;
  margin: 2rem auto;
  box-shadow: 0 4px 6px 6000px rgba(0, 0, 0, 0.6);
  text-align: center;
  font-weight: 100;
}

.drag-item {
  background-color: #2d2d2d;
  color: #ffffff;
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 6px;
  cursor: move;
  display: flex;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  user-select: none;
  transform-origin: center;
  text-align: left;
}

.drag-item:hover {
  background-color: #3d3d3d;
}

.drag-item.dragging {
  opacity: 0.8;
  transform: scale(1.02);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.drag-handle {
  margin-right: 1rem;
  color: #666;
  transition: color 0.2s ease;
  pointer-events: none;
}

.drag-item:hover .drag-handle {
  color: #888;
}

@keyframes slide {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.drag-item {
  animation: slide 0.3s ease-out;
}

.drag-ghost {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(74, 158, 255, 0.1);
  border-radius: 6px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.drag-item.dragging .drag-ghost {
  opacity: 1;
}
/* Base Styles */
.minimal-button {
  --primary-color: #4a567b; /* Dark blue */
  --hover-color: #303850; /* Darker blue on hover */
  --text-color: #ffffff; /* White text */
  --border-radius: 8px; /* Rounded corners */
  --padding-vertical: 12px; /* Vertical padding */
  --padding-horizontal: 24px; /* Horizontal padding */
  --font-size: 16px; /* Font size */
  --transition-duration: 0.3s; /* Animation duration */

  background-color: var(--primary-color);
  color: var(--text-color);
  font-size: var(--font-size);
  font-weight: 600;
  border: none;
  border-radius: var(--border-radius);
  padding: var(--padding-vertical) var(--padding-horizontal);
  cursor: pointer;
  transition: background-color var(--transition-duration),
    transform var(--transition-duration);
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

/* Hover State */
.minimal-button:hover {
  background-color: var(--hover-color);
  transform: translateY(-2px); /* Slight lift effect */
}

/* Active State */
.minimal-button:active {
  transform: translateY(0); /* Reset position */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduced shadow */
}

/* Focus State (Accessibility) */
.minimal-button:focus-visible {
  outline: 2px solid #6c63ff; /* Purple focus outline */
  outline-offset: 4px;
}

/* Loading Animation (Optional) */
.minimal-button.loading::after {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 10px;
  border: 2px solid var(--text-color);
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
