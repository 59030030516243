.simpleButton {
  background: transparent;
  border: 0.5px solid transparent;
  transition: 0.15s all ease;
  color: rgb(232, 232, 232);
  font-weight: 100;
  font-size: 16px;
}
.simpleButton:hover {
  background-color: rgb(217, 207, 193);
  border: 0.5px solid black;
  color: rgb(38, 38, 38);
  cursor: pointer;
  border-radius: 4px;
}
.buttonBox {
  border: 1px solid rgb(49, 49, 49, 0);
  border-radius: 6px;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
  background-color: #151515;
  color: rgb(194, 194, 194);
  width: fit-content;
  transition: 0.15s all ease;
}
.buttonBox:hover {
  border: 1px solid rgb(91, 91, 91);
  background-color: #1c1c1c;
}
.buttonBox:hover legend {
  background-color: #383838;
}
.buttonBox button {
  border-radius: 4px;
  border: none;
  margin: 1px;
  background: rgb(67, 67, 67);
  color: rgb(220, 220, 220);
}
.buttonBox legend {
  background-color: #151515;
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
}
.buttonBox button:hover {
  background-color: rgb(73, 73, 73);
  cursor: pointer;
}
.simpleButton2 {
  background: rgb(67, 67, 67);
  border: none;
  transition: 0.07s all ease;
  color: rgb(232, 232, 232);
  font-weight: 100;
  font-size: 16px;
  border-radius: 4px;
  margin: 2px;

  box-shadow: -2px 2px 0px 0px rgb(122, 122, 122);
}
.simpleButton2:hover {
  background-color: rgb(89, 89, 89);
  cursor: pointer;
}
.simpleButton2:active {
  margin-bottom: 0px;
  margin-top: 4px;
  margin-left: 0px;
  box-shadow: 0px 0px 0px 0px brown;
}
.simpleButton3 {
  background: transparent;
  /* background: rgb(21, 21, 21); */
  border: none;
  transition: 0.07s all ease;
  color: rgb(232, 232, 232);
  font-weight: 100;
  font-size: 16px;
  border-radius: 4px;
  padding: 3px;
  margin: 0px;
}
.simpleButton3:hover {
  background-color: rgb(64, 64, 64);
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.simpleButton3:active {
  background-color: rgb(86, 86, 86);
}
