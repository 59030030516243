.stats-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.stats-page h1 {
  font-size: 2.5rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 500;
}
.stats-page h2 {
  font-size: 3rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 100;
}
.finalStatsDiv {
  /* border: 1px solid red; */
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.finalStatsDiv p {
  color: darkgrey;
  font-weight: 100;
  font-size: 20px;
}
.savedTimeDiv {
  background-color: #fbe7af;
  color: rgb(32, 32, 32);
  /* font-size: 24px; */
  font-weight: 500;
  padding: 2px 5px;
  border-radius: 6px;
}
/* Grid Layout */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}
.countTotalRybasSpan {
  background-color: #131313;
  padding: 1px 3px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 100;
  margin: 1px;
}
/* Stats Card */
.stats-card {
  background-color: #2a2a2a;
  padding: 1.5rem;
  padding-top: 0.3rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stats-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.stats-card h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;

  color: #ffffff;
  border-bottom: 2px solid #444;
  padding-bottom: 0.5rem;
}

/* Stat Item */
.stat-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.stat-item span {
  color: #b0b0b0;
  font-weight: 100;
}

.stat-item .stat-value {
  color: #ffffff;
  font-weight: bold;
}

/* Leaderboard */
.leaderboard {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.leaderboard-item {
  display: flex;
  /* justify-content: space-between; */
  padding: 0px;
  background-color: #333;
  border-radius: 5px;
  width: 330px;
  margin-right: auto;
  margin-bottom: 4px;
  margin-left: auto;
}

.leaderboard-item .rank {
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  padding: 5px;
  width: 32px;
}

.leaderboard-item .name {
  color: #e9e9e9;
  font-size: 18px;
  font-weight: 100;
  width: 270px;
}

.leaderboard-item .speed {
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  padding: 3px;
  padding-right: 6px;
}
.yourPlace {
  text-align: center;
  font-size: 30px;
  color: #dbdbdb;
  font-weight: 900;
}
