/* General Styles */
/* body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #1a1a1a;
  color: #e0e0e0;
} */

.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  height: 700px;
  background: radial-gradient(
    circle,
    rgba(94, 94, 94, 0.4) 0%,
    rgba(87, 146, 163, 0.05) 25%,
    rgba(0, 0, 0, 0) 57%
  );
}

/* Header */
.about-us-header {
  text-align: center;
  margin-bottom: 4rem;
}

.about-us-header h1 {
  font-size: 3rem;
  margin: 0;
  color: #ffffff;
  animation: fadeIn 1s ease-in-out;
}

.about-us-header .subtitle {
  font-size: 1.5rem;
  font-weight: 100;
  color: #d7d7d7;
  animation: fadeIn 1.1s ease;
}

/* Content */
.about-us-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.about-us-card {
  background-color: #2a2a2a;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 2.5s ease-out;
}

.about-us-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

.about-us-card h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.about-us-card p {
  font-size: 1rem;
  line-height: 1.6;
  color: #eeeeee;
  font-weight: 100;
}

.about-us-card ul {
  list-style-type: none;
  padding: 0;
}

.about-us-card ul li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #b0b0b0;
}

/* Footer */
.about-us-footer {
  text-align: center;
  margin-top: 3rem;
  padding: 1rem 0;
  border-top: 1px solid #444;
}

.about-us-footer p {
  font-size: 0.9rem;
  color: #b0b0b0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
