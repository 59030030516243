.idea-page-container {
  font-family: sans-serif;
  background-color: #121212;
  color: #f0f0f0;
  padding: 20px;
  min-height: 100vh;
  align-items: center;
  width: 900px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  word-break: break-all;
  animation: fadeInAndMove 0.5s ease-in-out;
}

.idea-page-container h1 {
  text-align: center;
  margin-top: 9px;
  margin-right: auto;
  margin-left: 73px;
  margin-bottom: 2px;
  font-size: 32px;
  color: #e0e0e0;
  font-weight: 600;
}
.idea-page-container h2 {
  text-align: center;
  margin-right: auto;
  margin-left: 73px;
  margin-bottom: 20px;
  font-size: 22px;
  color: #e0e0e0;
  font-weight: 100;
}

.add-idea-button {
  background-color: #59afff;
  position: absolute;
  top: 50px;
  right: 70px;
  color: #121212;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 100;
}

.add-idea-button:hover {
  background-color: #1d82e0;
}

.textarea-container {
  margin-bottom: 20px;
  background-color: #0c0c0c;
  padding: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.textarea-container p {
  font-weight: 100;
}
.textarea-container-edit {
  position: fixed;
  top: 200px;
  width: 50vw;
  z-index: 1000;
  margin-bottom: 20px;
  background-color: #0c0c0c;
  padding: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(69, 69, 69);
  box-shadow: 0px 0px 1000px 500px black;
}
.textarea-container-edit h2 {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.idea-page-container textarea {
  outline: none;
  width: 400px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #222;
  color: #f0f0f0;
  resize: vertical;
  margin-bottom: 10px;
  resize: none;
  font-weight: 100;
}

.submit-idea-button {
  background-color: #03dac6;
  color: #121212;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-idea-button:hover {
  background-color: #018786;
}

.ideas-container {
  display: flex;
  gap: 20px;
  width: 100%;
}

.ideas-list,
.ideas-column {
  flex: 1;
  background-color: #1e1e1e;
  padding: 15px;
  border-radius: 5px;
}

.ideas-column {
  width: 20%;
}

.ideas-list h2,
.ideas-column h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #bb86fc;
  text-align: center;
}

.idea-item {
  background-color: #282828;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
  position: relative;
}

.idea-item:hover {
  background-color: #2c2c2c;
}

.vote-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.vote-buttons button {
  background-color: #333;
  color: #f0f0f0;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #4398ff;
}

.vote-buttons button:hover {
  background-color: #555;
}
.ideaTitle {
  font-size: 22px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
  color: #51abff;
}
.ideaContent {
  font-size: 16px;
  font-weight: 100;
  margin-bottom: 10px;
}
.likesContainer {
  position: absolute;
  top: 5px;
  right: 5px;
}
.likesContainer button {
  background-color: rgb(27, 27, 27);
  border-radius: 6px;
  text-align: center;
  vertical-align: middle;
  border: none;
  transition: all 0.2s ease;
  color: grey;
  margin-left: 5px;
  padding: 4px;
}
.likesContainer svg {
}
.likesContainer button:hover {
  background-color: #1e1e1e;
}
.idea-item:hover .commentButton {
  opacity: 1;
}
.commentButton {
  opacity: 1;
  background-color: rgb(55, 55, 55, 1);
  border-radius: 6px;
  text-align: center;
  vertical-align: middle;
  border: none;
  transition: all 0.2s ease;
  color: rgb(155, 155, 155);
  margin: 0px;
  padding: 3px;
  font-weight: 100;
}
.commentButton:hover {
  background-color: #222222;
}
.infoContainer {
  display: flex;
  justify-content: right;
  font-weight: 100;
  font-size: 13px;
  color: grey;
}
.tagsForIdea {
  width: 200px;
  background-color: #222;
  color: rgb(201, 201, 201);
  border: none;
  border-radius: 6px;
  padding: 5px;
}
.sectionsIdeas {
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  right: -245px;

  width: 225px;
  background-color: #121212;
  color: #f0f0f0;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* min-height: 20vh; */
  align-items: center;

  display: flex;
  flex-direction: column;
  word-break: break-all;
  border-radius: 6px;
}
.sectionsIdeasSegment {
  display: flex;
  font-weight: 100;
  margin: 10px;
  margin-bottom: 0px;
  margin-top: 0px;

  width: 95%;
  justify-content: left;
  align-items: left;
  justify-items: left;
  position: relative;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sectionsIdeasSegment:hover {
  background-color: #222222;
  cursor: pointer;
}
.sectionsIdeasSegment p {
  padding: 5px;
  margin: 0px;
  margin-right: auto;
  width: 120px;
}
.sectionsIdeasSegment svg {
  margin-right: auto;
  margin-left: 10px;
}
.sectionsIdeasSegment span {
  position: absolute;
  top: 15px;
  right: 10px;
  color: grey;
}

/* Styling for the select element */
.select-container {
  display: inline-block;
  position: relative;
}

.select-container select {
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #1e1e1e; /* Dark input background */
  border: 1px solid #333333; /* Subtle border */
  border-radius: 4px; /* Rounded corners */
  color: #d7d7d7; /* White text */
  padding: 8px 12px; /* Padding for better spacing */
  font-size: 14px; /* Font size */
  width: 100%; /* Full width */
  cursor: pointer; /* Pointer cursor for interactivity */
  outline: none; /* Remove focus outline */
  transition: border-color 0.2s ease; /* Smooth transition for hover effect */
  margin: 4px;
  margin-left: 0px;
  margin-bottom: 10px;
}

/* Hover effect */
.select-container select:hover {
  border-color: #555555; /* Slightly lighter border on hover */
}

/* Focus effect */
.select-container select:focus {
  border-color: #007bff; /* Blue border on focus */
}

/* Custom dropdown arrow (using pseudo-element) */
.select-container::after {
  content: "▼"; /* Downward arrow symbol */
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't interfere with clicks */
  color: #555555; /* Arrow color */
  font-size: 12px;
}

/* Styling for the dropdown options */
.select-container select option {
  background-color: #1e1e1e; /* Dark option background */
  color: #ffffff; /* White text */
  padding: 4px; /* Padding for better readability */
}

/* Highlight selected option */
.select-container select option:checked {
  background-color: #007bff; /* Blue highlight for selected option */
  color: #ffffff; /* White text */
}
.commentSectionContainer {
  background-color: transparent;
  width: 90%;
  margin-left: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: all 0.2s ease;
  position: relative;
}
.addComment {
  display: flex;
}
.addComment button {
  height: 30px;
  border-radius: 6px;
  border: none;
  font-weight: 100;
  background-color: #afafaf;
  margin: 4px;
  margin-left: 7px;
}
.commentContainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  margin: 8px;
}
.commentInfo {
}
.replyTo {
  background-color: rgb(20, 20, 20, 0.7);
  padding: 4px;
  padding-right: 6px;
  border: 0.5px solid rgb(0, 0, 0);
  width: fit-content;
  font-style: italic;
  border-radius: 6px;
  font-size: 14px;
  color: rgb(170, 170, 170);
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 100;
}
.commentContent {
  font-size: 15px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  padding-top: 4px;
  padding-bottom: 4px;
}
.commentLikes button {
  background-color: transparent;
  color: darkgrey;
  border: none;
  font-weight: 100;
  font-size: 12px;
}
.commentName {
  font-size: 15px;
  font-weight: 100;
  color: rgb(203, 203, 203);
}
.commentTime {
  font-size: 14px;
  font-weight: 100;
  color: rgb(203, 203, 203);
  margin-left: 5px;
}
.closeComments {
  height: 30px;
  border-radius: 6px;
  border: none;
  font-weight: 100;
  background-color: #afafaf;
  margin: 4px;
  margin-left: 7px;
}
.refreshComments {
  /* margin-top: 9px; */
  background-color: transparent;
  border: none;
  /* border: 1px solid red; */
}
.refreshComments svg {
  /* padding-bottom: 12px; */
}
/* Container for the list of ideas */
.ideasContainer {
  /* display: flex;
  flex-direction: column;
  gap: 1rem; */
}

/* Individual idea item */
.ideaItem {
  opacity: 0; /* Start with items invisible */
  transform: translateY(20px); /* Start slightly below their final position */
  animation: fadeInUp 0.5s ease forwards;
}

/* Delay each item's animation */
.ideaItem:nth-child(1) {
  animation-delay: 0.2s;
}
.ideaItem:nth-child(2) {
  animation-delay: 0.4s;
}
.ideaItem:nth-child(3) {
  animation-delay: 0.6s;
}
.ideaItem:nth-child(4) {
  animation-delay: 0.8s;
}

/* Keyframes for the fade-in animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.creatorName {
  position: relative;
  padding-right: 25px;
  font-weight: 900;
  color: rgb(240, 240, 173);
}
.creatorName svg {
  position: absolute;
  top: -5px;
  right: -2px;
}
/* Base styles for the select element */
.myFirstDevSelect {
  background-color: #2d3436; /* Dark background */
  color: #ffffff; /* Light text */
  border: 1px solid #444; /* Subtle border */
  border-radius: 5px; /* Rounded corners */
  padding: 2px; /* Padding inside the select */
  font-size: 15px; /* Font size */
  font-weight: 100;
  outline: none; /* Remove default outline */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transitions */
  margin-left: 4px;
}

/* Hover effect */
.myFirstDevSelect:hover {
  background-color: #3a4145; /* Slightly lighter background */
  border-color: #555; /* Brighter border */
}

/* Focus effect */
.myFirstDevSelect:focus {
  background-color: #4b5357; /* Even lighter background */
  border-color: #00bcd4; /* Highlight border */
  box-shadow: 0 0 8px rgba(0, 188, 212, 0.5); /* Subtle glow */
}

/* Disabled state */
.myFirstDevSelect:disabled {
  background-color: #444; /* Darker background for disabled state */
  color: #888; /* Grayed-out text */
  cursor: not-allowed; /* Not-allowed cursor */
}

/* Option styling (limited browser support) */
.myFirstDevSelect option {
  background-color: #2d3436; /* Match the select background */
  color: #ffffff; /* White text */
}
