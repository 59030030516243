.search-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  color: #333; /* Dark text for readability */
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #bebebe; /* Deepseek's primary blue */
}

.search-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.search-textarea {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  min-width: 550px;
  text-align: center;
  height: 20px;
}

.search-textarea:focus {
  border-color: #1a73e8; /* Deepseek's primary blue */
}

.search-button {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff; /* White text */
  background-color: #1a73e8; /* Deepseek's primary blue */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #1557b7; /* Darker shade of Deepseek's blue */
}

.search-button:active {
  transform: scale(0.95);
}

.resultsCount {
  font-size: 15px;
  color: #666; /* Gray text */
  margin-bottom: 10px;
}

.results-container {
  margin-bottom: 10px;
}

.result-item {
  padding: 3px;
  background-color: #333;
  margin: 5px;
  width: 1210px;
  display: flex;
  border-radius: 6px;
}
.result-item:hover {
  cursor: pointer;
  background-color: #2b2b2b;
}

.result-title {
  font-size: 18px;
  width: 200px;
  font-weight: bold;
  color: #1a73e8; /* Deepseek's primary blue */
  margin-bottom: 5px;
  text-align: left;
}

.result-content {
  font-size: 14px;
  padding: 5px;
  width: 850px;
  text-align: left;
  color: #cdcdcd; /* Gray text */
}

.no-results {
  text-align: center;
  font-size: 16px;
  color: #666; /* Gray text */
}

.pagination-container {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 10px;
}

.page-number {
  padding: 5px 10px;
  font-size: 14px;
  color: #1a73e8; /* Deepseek's primary blue */
  background-color: #e8f0fe; /* Light blue background */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.page-number:hover {
  background-color: #1a73e8; /* Deepseek's primary blue */
  color: #fff; /* White text */
}

.page-number.active-page {
  background-color: #1557b7; /* Darker shade of Deepseek's blue */
  color: #fff; /* White text */
}

.page-number.active-page:hover {
  background-color: #1557b7; /* Darker shade of Deepseek's blue */
}
.radioSearch {
  background-color: #3a3a3a;
  border-radius: 6px;
  padding: 3px;
  border: 0.5px solid #333333;
  margin-bottom: 3px;
}
.filtersContainer {
  display: flex;
  flex-direction: column;
  margin: 5px;
  /* border: 1px solid red; */
  background-color: #101010;
  width: 700px;
  border-radius: 6px;
  padding: 5px;
}
.filtersSegment {
  display: flex;
  margin: 4px;
  color: rgb(152, 152, 152);
}
.foundButtonStats {
  /* background-color: #404040; */
  margin: 4px;
  padding: 3px;
  border-radius: 6px;
  text-align: right;
  width: 200px;
}
.foundButtonStats p {
  color: grey;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  text-align: right;
}
