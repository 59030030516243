.RYBA4rybaSETTINGS {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 50px;
  width: 675px;
  background: #2d2d2d;
  color: #dddddd;
  border-radius: 10px;
  animation: fadeIn 0.6s;
  font-weight: 100;
}
.userInfoSegment {
  display: flex;
  flex-direction: row;
  width: 800px;
  justify-content: left;
  vertical-align: center;
  margin: 4px;
  font-weight: 100;
}

.userInfoSegment p {
  padding: 0px;
  margin: 5px;
  width: 200px;
}
.textInput {
  background-color: #0a0a0a;
  color: rgb(212, 212, 212);
  padding: 3px;
  border-radius: 6px;
  outline: none;
  font-size: 16px;
  margin-left: 10px;
  width: 250px;
  font-weight: 100;
}
.profileShapka {
  display: flex;
  flex-direction: column;
  width: 450px;
  text-align: center;
}
.profileShapka button {
  background-color: rgb(175, 175, 175);
  border-radius: 6px;
  border: none;
  padding: 4px;
  margin: 3px;
}
.profileShapka button:hover {
  background-color: rgb(209, 209, 209);
  cursor: pointer;
}
.profileShapka textarea {
  width: 100%;
  background-color: rgb(175, 175, 175);
  border-radius: 6px;
  padding: 3px;
  margin: 3px;
  outline: none;
}
.userInfoTitle p {
  font-size: 1.2rem;
  color: rgb(165, 165, 165);
}
