.popupContainerConstructor {
  width: 100vw;
  height: 200vh;
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  animation: fadeIn 0.4s;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
  overflow-x: hidden;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.constructorWindow {
  font-weight: 100;
  font-size: 18px;
  padding: 32px;
  box-shadow: -3px 32px 47px -29px rgba(29, 17, 51, 0.6);
  background: rgb(23, 23, 23);
  width: 550px;
  height: fit-content;
  color: #dddddd;
  text-align: center;
  border-radius: 10px;
  /* margin-left: auto;
  margin-right: auto;  */
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 200px;
  z-index: 50;
  animation: fadeIn2 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: none;
  box-shadow: 0px 0px 10000px 100000px rgb(0, 0, 0, 0.8);
}
.inputPromptSpan {
  background-color: rgb(216, 216, 216);
  color: black;
  font-weight: bold;
  border-left: 10px;
  border-right: 10px;
  border-color: lightblue;
  border-style: solid;
  animation: 1s ease-in 0.1s infinite reverse both running glowOw;
}
@keyframes glowOw {
  0% {
    border-color: rgb(66, 30, 196, 0.2);
  }
  50% {
    border-color: rgb(66, 30, 196);
  }
  100% {
    border-color: rgb(66, 30, 196, 0.2);
  }
}
.constructorWindow button {
  padding: 10px;
  font-size: 16px;
  font-weight: 100;
  color: #dddddd;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 10px;
  background-color: rgb(11, 11, 11);
  margin-left: auto;
  margin-right: auto;
  margin: 10px 2px 2px 2px;
}
@keyframes fadeIn2 {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.constructorWindow .selectModality {
  width: 100px;
}
.constructorWindow p {
  font-size: 19px;
  padding: 0px;
  margin: 10px;
}
.constructorWindow textarea {
  font-family: sans-serif;
  font-weight: 100;
  font-size: 16px;
  padding: 10px;
  margin: 7px;
  border: 0.5px solid rgb(10, 10, 10);
  border-radius: 10px;
  background: rgb(10, 10, 10);
  color: #dddddd;
  outline: none;
  transition: all 0.15s ease-in-out;
  width: 90%;
  box-shadow: 0px 0px 12px 0.6px rgba(77, 47, 132, 0.3);
}
.constructorWindow textarea:focus {
  border: 0.5px solid rgb(35, 5, 35);
  box-shadow: 4px 0px 22px 2px rgba(77, 47, 132, 0.6);
  outline: none;
}
.constructorWindow textarea:hover {
  border: 0.5px solid rgb(80, 14, 80);
}
.constructorWindow h1 {
  font-weight: 500;
  font-size: 34px;
  margin: 0px;
  margin-bottom: 15px;
}
h5 {
  font-weight: 100;
  color: grey;
  padding-bottom: 5px;
  margin: 0px;
}
.lungWhere {
  width: 650px;
  top: 130px;
}
.lungWhere fieldset {
  border-radius: 6px;
  border: 1px solid transparent;
  margin: 4px;
  background-color: rgb(41, 41, 41);
}
.lungWhere hr {
  border: 0.5px solid transparent;
  margin: 6px;
}
.lungWhere input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
.lungWhere input[type="radio"]:checked {
  accent-color: rgb(225, 221, 100);
}

.lungWhere button {
}
.lungWhere button:hover {
  background-color: rgb(55, 55, 55);
  cursor: pointer;
}

.checkboxLabel {
  /* background-color: rgb(78, 78, 78); */
  padding: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 0px;
  border: 0.5px solid rgb(78, 78, 78);
  /* border-radius: 2px; */
}
.checkboxLabel:hover {
  background-color: rgb(61, 61, 61);
  cursor: pointer;
  color: white;
  /* border: 0.5px solid rgb(78, 78, 78); */
  border-radius: 2px;
}
.checkboxLabelChecked {
  background-color: rgb(207, 218, 153);
  color: rgb(19, 19, 19);
}
.checkboxLabelChecked:hover {
  color: rgb(19, 19, 19);
  background-color: rgb(207, 218, 153);
}
.fieldsetMini {
  /* font-size: 15px; */
  scale: 0.87;
}
.fieldsetMini input[type="text"] {
  background-color: rgb(21, 21, 21);
  margin: 6px;
  color: rgb(215, 215, 215);
  width: 50px;
  text-align: center;
}

.lymphadenopathy {
  width: 650px;
  top: 130px;
}
.lymphadenopathy fieldset {
  font-size: 16px;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid transparent;
  margin: 2px;
  background-color: rgb(41, 41, 41);
}
.lymphadenopathy hr {
  border: 0.5px solid transparent;
  margin: 6px;
}
.lymphadenopathy input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
.lymphadenopathy input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
.lymphadenopathy input[type="radio"]:checked {
  accent-color: rgb(225, 221, 100);
}

.lymphadenopathy button {
}
.lymphadenopathy button:hover {
  background-color: rgb(55, 55, 55);
  cursor: pointer;
}
.groupLymph {
  border: none;
  border-radius: 6px;
  margin: 6px;
  padding: 4px;
  background-color: rgb(0, 0, 0);
}
.groupLymph input[type="number"] {
  background-color: black;
  color: white;
}
.groupLymph input[type="text"] {
  background-color: rgb(37, 37, 37);
  margin: 2px;
  border-radius: 6px;
  border: none;
  color: rgb(236, 236, 236);
  outline: none;
  padding: 3px;
}
.nameGroupLymph {
  background-color: rgb(49, 49, 49, 0);
  padding: 2px;
  padding-bottom: 4px;
  color: #d7d7d7;
  display: flex;
  justify-content: space-between;
}
.radioLabel {
  padding: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin: 0px;
  border: 0.5px solid rgb(78, 78, 78);
  border-radius: 6px;
  margin: 2px;
  font-size: 15px;
  color: #5e5e5e;
}
.radioLabel:hover {
  padding: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.radioLabelChecked {
  background-color: rgb(207, 218, 153);
  color: rgb(19, 19, 19);
}
.radioLabelChecked:hover {
  color: rgb(19, 19, 19);
  background-color: rgb(207, 218, 153);
}
.dinamika {
}
