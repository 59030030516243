.notificationWindow {
  background-color: rgb(242, 249, 210);
  width: 300px;
  position: fixed;
  color: black;
  text-align: center;
  border-radius: 50px;
  padding: 5px;
  top: 20%;
  right: 2%;
  z-index: 5;
  animation: fadeIn3 3s;

  display: flex;
  box-shadow: 10px 10px 35px black;

  flex-direction: column;
}
@keyframes fadeIn3 {
  0% {
    transform: scale(0.2);
  }
  6% {
    transform: scale(1);
  }
  85% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
