.greySpan {
  font-weight: 100;
  color: grey;
  font-size: 14px;
}
.calcRedactorContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: #121212;
  z-index: 100 !important;
  overflow: scroll;
}
.calcRedactorContainer h2 {
  /* text-align: left; */
  font-weight: 500;
  color: #85b4ce;
  font-size: 1.6rem;
  text-align: left;
  margin-left: 8px;
}

.calcRedactorContainer textarea {
  border: 0.5px solid rgb(124, 131, 133);
  border-radius: 6px;
  padding: 3px;
  margin: 2px;
  background-color: #000000;
  color: rgb(214, 229, 234);
  transition: 0.2s all ease;
}
.calcRedactorContainer textarea:focus {
  border: 0.5px solid rgb(190, 221, 233);
}

.flexColumn {
  display: flex;
  flex-direction: column;
}
.redactorsCalcContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
  /* border: 1px solid red; */
}
.redactorElement {
  background-color: #2f2f2f;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 5px;
}
.menuRedactor {
  padding: 10px;
  /* width: 550px; */
}
.menuRedactor textarea {
  width: 200px;
}
.deleteCalcElement {
  background-color: pink;
}
.formulaEditing {
  font-size: 1.1rem;
}
.booleanEditing {
}
.radioContainer {
}
.calculatorContainerFormula button {
  width: 25px;
  height: 25px;
}
.calculatorContainerFormula button:hover {
}
.formulaDisplay {
  background-color: rgb(0, 0, 0);
  padding: 8px;
}
.addElementsCalcContainer button {
  background-color: #4d4d4d;
  color: rgb(228, 228, 228);
  border-radius: 6px;
  margin: 4px;
  padding: 3px 6px;
  transition: 0.2s all ease;
  font-weight: 100;
  border: none;
}
.addElementsCalcContainer button:hover {
  background-color: #72c675;
  color: rgb(38, 38, 38);
}
.logicTypeSpan {
  background-color: #000000;
  padding: 2px 4px;
  margin: 5px;
  border: 1px solid orange;
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(39, 39, 39);
  background-color: orange;
  border-radius: 6px;
}
.logicContainerDiv {
  margin-top: 5px;
  padding: 5px;
}
.logicContextMenu {
  background-color: #434343;
  padding: 0px;
  color: rgb(236, 236, 236);
  font-weight: 100;
}
.logicContextMenu p {
  position: relative;
  padding: 10px;
  margin: 0px;
}
.logicContextMenu p:hover {
  background-color: #2f2f2f;
  cursor: pointer;
}
/*//////*/
.sideMenu {
  position: absolute;
  top: 0;
  left: 100%; /* Position to the right of the p element */
  width: 150px;
  background-color: #646464;
  padding: 0px;
  display: none; /* Hidden by default */
  z-index: 10;
}
.sideMenu p:hover {
  background-color: #2f2f2f;
  cursor: pointer;
}
.deleteButtonUniversal {
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 3px;
  font-weight: 100;
  color: rgb(238, 238, 238);
  background-color: rgb(117, 117, 117);
  transition: all 0.15s ease;
}
.deleteButtonUniversal:hover {
  background-color: pink;
  color: black;
}
.greenOnHover:hover {
  background-color: lightgreen;
}
.calculatorFinalContainer {
  /* border: 1px solid red; */
}
.valueRedactorSegment {
  display: flex;
}
.valueRedactorSegment button {
  background-color: transparent;
  height: 36px;
  border-radius: 6px;
  border: none;
  margin: 2px;
  background-color: #2f2f2f;
}
.valueRedactorSegment button:hover {
  background-color: #1f1f1f;
}
/* Base styles for the select element */
.calculatorSelectMoreOrLess {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px;
  padding-bottom: 8px;
  margin: 3px;
  border: none;
  border-radius: 8px;
  background-color: #333;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: 400;
  color: orange;
  font-size: 16px;
}
.calculatorSelect {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px;
  padding-bottom: 8px;
  margin: 2px;
  border: none;
  border-radius: 8px;
  /* background-color: #333; */
  background-color: transparent;
  border: 1px solid rgb(78, 78, 78);
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: 100;
  color: rgb(207, 207, 207);
  font-size: 14px;
  width: 150px;
}
.svgButtonCalc {
  background-color: transparent;
  border: none;
  border-radius: 6px;
  opacity: 0.8;
}
.svgButtonCalc:hover {
  background-color: #1f1f1f;
  opacity: 1;
}
.variableContainer {
  margin: 5px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.variableContainer span {
  font-weight: 100;
  margin-right: 5px;
  width: 200px;
}
.variableContainer input {
  padding: 6px;
  border-radius: 6px;
  background-color: #000000;
  border: 0.5px solid rgb(0, 0, 0, 0);
  text-align: center;
  outline: none;
  transition: 0.15s all ease;
  color: rgb(215, 215, 215);
  font-weight: 100;
  font-size: 15px;
  width: 130px;
}
.variableContainer input:hover {
  border: 0.5px solid rgba(0, 114, 155, 0.54);
  text-align: center;
  outline: none;
}

.variableContainer input:focus {
  border: 0.5px solid #00bbff;
  text-align: center;
  outline: none;
}
.booleanContainer {
  margin: 10px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 6px;
  padding: 7px;
  padding: 0px;
  width: 500px;
  font-weight: 100;
  font-size: 15px;
  transition: 0.15s all ease;
}
.booleanContainer:hover {
  background-color: #1f1f1f;
  color: rgba(167, 232, 255, 0.762);
  cursor: pointer;
}
.booleanContainer input:hover {
  background-color: #1f1f1f;
  cursor: pointer;
}
.booleanContainer label {
  padding: 7px;
}
.radioContainer {
  /* margin: 5px; */

  margin-left: auto;
  margin-right: auto;
}
.radioContainer label {
  padding: 3px;
  font-weight: 100;
}

.radioContainer label:hover {
  /* background-color: #c70000; */
  color: #a3dff5;
  cursor: pointer;
}
.menuContainer {
  margin: 10px;
}
.menuContainer select:hover {
  background-color: #00bbff3c;
}
.logicItemsButton {
  background-color: #313131;
  padding: 5px;
  height: 34px;
  border: none;
  margin: 3px;
  border-radius: 6px;
}
.logicItemsButton:hover {
  background-color: #3c3c3c;
}

.notFocusedStep {
  margin: 7px;
  border: 1px solid rgb(35, 35, 35);
  border-radius: 6px;
  padding: 5px;
  display: flex;
  background: rgb(9, 9, 9);
  /* background-color: transparent; */
}
.notFocusedStep p {
  padding: 0px;
  margin: 2px;
}
.notFocusedStep:hover {
  background-color: #151515;
  border: 1px solid rgb(98, 98, 98);
}
.orangeSpan {
  color: orange;
  font-weight: 500;
}

.calcRedactorContainer fieldset {
  margin: 5px;
  margin-top: 9px;
  border-radius: 6px;
  border: 1px solid rgb(91, 91, 91);
}
.calcRedactorContainer fieldset legend {
  background-color: #2f2f2f;
  border: 1px solid rgb(67, 67, 67);
  font-size: 1.15rem;
  font-weight: 100;
  padding: 3px 6px;
  border-radius: 6px;
}
.openedStep {
  background-color: #000000;
}
.openedStep legend {
  background: #00bbff;
  color: orange;
  border: 1px solid orange;
}
/* .redactorCalcDiv {
  margin: 5px;
  margin-top: 9px;
  border-radius: 6px;
  background-color: #090909;
  padding: 9px;
}
.redactorCalcDiv h3 {
  text-align: left;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-weight: 500;
  color: darkgrey;
} */
.listSpan {
  display: inline-block;
  background-color: rgb(0, 0, 0);
  padding: 4px;
  border: 1px solid rgb(77, 77, 77);
  /* border-radius: 6px; */
  color: darkgrey;
  opacity: 0.95;
  transition: 0.1s all ease;
  margin-bottom: 0px;
}
.listSpan:hover {
  opacity: 1;
  background-color: #00bbff35;
}
.checkedListSpan {
  background-color: orange;
  color: black;
  border: 1px solid darkgrey;
}

.checkedListSpan:hover {
  background-color: orange;
}
.forRyba4Fix {
  text-align: center;
  color: darkgrey;
  font-size: 14px;
}
.descriptionCalc {
  white-space: pre-wrap;
  background-color: #080808;
  color: grey;
  font-size: 14px;
  padding: 8px;
  border-radius: 6px;
  font-weight: 100;
  margin: 5px;
}
