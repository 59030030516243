.howCONTAINER {
  padding-top: 10px;
  background: rgb(48, 48, 48);

  padding-bottom: 10px;
  font-weight: 100;
}
.how {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 50px;
  width: 1300px;

  background: #414141;
  color: #dddddd;
  border-radius: 10px;
  animation: fadeIn 0.6s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  text-align: center;
  background-color: #060606;
}

@keyframes fadeIn {
  0% {
    background-color: #000;
    background: #000;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.contents {
  width: 250px;
  padding: 10px;
  font-size: 18px;
}
.content1 {
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
}
.content1:hover {
  background-color: #1c1c1c;
  color: white;
}
.articlesCONTAINER {
  width: 1000px;

  text-align: center;
}
.article {
  height: fit-content;
  padding: 15px;
  background-color: #101010;
  border-radius: 6px;
  margin: 10px;
  color: #cccccc;
}
.howCONTAINER h1 {
}
.howCONTAINER h1 {
}
.howCONTAINER h1 {
}
.howCONTAINER p {
}
.article img {
  border-radius: 6px;
  box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
}
.bold {
  font-weight: 500;
}
.grey {
  color: grey;
}
.yellow {
  color: yellow;
}
.lightblue {
  color: rgb(154, 171, 238);
}
.red {
  color: rgb(223, 0, 0);
  font-weight: 500;
}
.howHORIZONTAL {
  display: flex;
}
.howLEFT {
  width: 300px;
  padding: 10px;
}
.howRIGHT {
  width: 700px;
}
