* {
  font-family: sans-serif;
  scroll-behavior: smooth;
}
html {
  height: 100%;
  overflow: auto;
  font-family: sans-serif;
}
body {
  height: 100%;
}
textarea {
  overflow: hidden;
}
:root {
  --primary: #323169;
  --error: #e7195a;
}

body {
  background: #0a0a0a;
  margin: 0;
}
.svg {
  color: blue;
  background-color: transparent;
  scale: 1.3;
  stroke-width: 1.2;
  z-index: -31;
  pointer-events: none;
}
.svg:hover {
  cursor: pointer;
}
header {
  width: 100%;
  background: transparent;

  display: flex;
  position: relative;
  justify-content: center;
  z-index: 13;
}
@media print {
  header {
    display: none;
  }
}
header .container {
  width: 1200px;
  /* width: 60vw; */
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #dddddd;
  text-decoration: none;
}
.rybovProdaete {
  font-family: sans-serif;
  height: 90px;
  width: 290px;
}

.logoIMG {
  width: 110px;
}
.logoTXT {
  width: 200px;
}
.logoTXT h1 {
  font-weight: 900;
  font-family: sans-serif;
}
.navbarLinksContainer {
  display: flex;
  flex-direction: column;

  width: 700px;
}
.navbarTop {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 20px;
  /* border: 1px solid red; */
}

.navbarBottom {
  display: flex;
  width: 100%;
  /* border: 1px solid blue; */
  height: 20px;
  justify-content: center;
  justify-items: center;
}
.navbarBottom p {
  background-color: #313131;
  font-weight: 500px;
  margin-left: 15px;
  border-radius: 6px;
  padding: 3px;
}
.navbarLink {
  font-family: sans-serif;
  padding: 0px;
  transition: all 0.15s ease-in-out;
}

.logout {
  margin-right: 20px;
  text-align: center;
}

.login {
  background: rgb(23, 23, 23);
  box-shadow: -3px 32px 47px -29px rgb(81, 93, 186, 0.6);
  font-size: 1.25rem;
  font-weight: 100;
  font-family: sans-serif;
  color: rgb(217, 217, 217);
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  border-radius: 8px;
}
.login input {
  font-weight: 100;
}
.inputLoginSignup {
  border-radius: 6px;
  padding: 9px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  background: transparent;
  border: none;
  background-color: #0a0a0a;
  color: white;
  transition: all 0.15s ease-in-out;
  border: 0.5px solid rgb(124, 41, 123, 0);
  font-weight: 100;
}
.inputLoginSignup:hover {
  border: 0.5px solid rgb(81, 93, 186, 0.5);
  outline: none;
}
.inputLoginSignup:active {
  outline: none;
  border: 0.5px solid #515dba;
}
.inputLoginSignup:focus {
  outline: none;
  border: 0.5px solid #515dba;
}

.home {
  display: flex;
  flex-direction: row;
}

.pages {
}
.rybaList {
  text-align: left;
  margin: 30px;
  padding: 25px;
  width: 300px;
  height: fit-content;
  min-height: 100%;
  background: rgb(23, 23, 23);
  border-radius: 10px;
  border: none;
  color: #dddddd;
  margin-top: 10px;
  box-shadow: -3px 32px 47px -29px rgba(81, 93, 186, 0.2);
  font-size: 1.3rem;
  font-weight: 100;
  font-family: sans-serif;
  /* animation: fadeIn 0.6s; */
  transition: all 0.15s ease-in-out;
}
.rybaListContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rybaListSubcontainer1 {
  padding: 30px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
}

.modalityIcon {
  width: fit-content;
  height: fit-content;
}
.rybaList p {
  font-size: 1.8rem;
  font: large;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
}
.rybaList button {
  border-radius: 10px;
  margin-top: 20px;
}
.rybaList a {
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}
.rybaList a:link {
  color: #e5e5e5;
}
.rybaList a:visited {
  color: #e5e5e5;
}
.rybaList a:hover {
  color: #515dba;
  cursor: pointer;
}
.rybaList a:active {
  color: #515dba;
  cursor: pointer;
}
.rybaList li {
  list-style-type: none;
  padding: 1px;
}

textarea {
  resize: none;
}
.modalityContainer {
  padding: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  display: flex;
}
@keyframes fadeInAndMove {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Move further up */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalityList {
  text-align: center;
  margin: 20px;
  padding: 15px;
  padding-bottom: 15px;
  width: 145px;
  height: fit-content;

  background: rgb(23, 23, 23);
  border-radius: 10px;
  border: none;
  color: #dddddd;
  margin-top: 0px;
  box-shadow: -3px 32px 47px -29px rgba(80, 92, 185, 0.2);
  font-size: 1.7rem;
  font-weight: 500;
  font-family: sans-serif;
  animation: fadeInAndMove 0.6s;
  transition: all 0.15s ease-in-out;
  display: inline-block;
}
.modalityIcon {
  margin-right: auto;
  margin-left: auto;
  width: fit-content;
  height: fit-content;
}
.modalityList p {
  font-size: 1.4rem;
  font: large;
  font-weight: 100;
  padding: 0px;
  margin: 0px;
  margin-bottom: 0px;
}
.modalityList:hover {
  color: rgb(220, 220, 220);
  background-color: rgba(54, 54, 54, 0.6);
}
.undertext {
  font-size: 14px;
  font-weight: 100;
  padding: 0px;
  margin: 0px;
}

fieldset label {
  display: block;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.spanTurbo {
  font-size: 32px;
  font-weight: 600;
  color: #e4e4e4;
  font-family: sans-serif;
}
.spanRyba {
  font-size: 32px;
  color: #e4e4e4;
}
/* HTML: <div class="loader"></div> */
.loader {
  --r1: 154%;
  --r2: 68.5%;
  width: 230px;

  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(
      var(--r1) var(--r2) at top,
      #0000 79.5%,
      #269af2 80%
    ),
    radial-gradient(var(--r1) var(--r2) at bottom, #269af2 79.5%, #0000 80%),
    radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, #269af2 80%), #ccc;
  background-size: 50.5% 220%;
  background-position: -100% 0%, 0% 0%, 100% 0%;
  background-repeat: no-repeat;
  animation: l9 2s infinite linear;
  margin-left: auto;
  margin-right: auto;
}
@keyframes l9 {
  33% {
    background-position: 0% 33%, 100% 33%, 200% 33%;
  }
  66% {
    background-position: -100% 66%, 0% 66%, 100% 66%;
  }
  100% {
    background-position: 0% 100%, 100% 100%, 200% 100%;
  }
}
/* HTML: <div class="loaderSpinner"></div> */
.loaderSpinner {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  margin-right: auto;
  margin-left: auto;
}
.loaderSpinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #dfdfdf;
  animation: prixClipFix 3s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
.contextMenu {
  width: 106px;
  padding: 3px;
  min-height: 100px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #292929;
  color: white;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 10px 1px rgb(0, 0, 0);
  border-radius: 6px;
}
.contextMenu p {
  padding: 0px;
  margin: 2px;
}
.contextMenu button {
  background-color: #777777;
  border-radius: 6px;
  border: none;
  padding: 3px;
  margin: 1px;

  transition: 0.15s all ease;
}
.contextMenu button:hover {
  background-color: #9d9d9d;
  cursor: pointer;
}
.createRyba {
  border-radius: 6px;
  padding: 5px;
  background-color: #808080;
  border: none;
  color: #1a1a1a;
}
.createRyba:hover {
  cursor: pointer;
  background-color: #a3a3a3;
}

.saveChanges:hover {
  background-color: rgb(73, 73, 73);
  cursor: pointer;
}
.closeTextEditWindow:hover {
  background-color: rgb(73, 73, 73);
  cursor: pointer;
}
.savedList {
  text-align: left;
  margin: 10px;
  padding: 15px;
  width: 1100px;
  height: fit-content;
  min-height: 100%;
  background: rgb(23, 23, 23);
  border-radius: 10px;
  border: none;
  color: #dddddd;
  margin-top: 10px;
  box-shadow: -3px 32px 47px -29px rgba(81, 93, 186, 0.3);
  font-size: 1.1rem;
  font-weight: 100;
  font-family: sans-serif;
  animation: fadeInAndMove 0.6s;
  transition: all 0.15s ease-in-out;
}
.savedList p {
  font-size: 2rem;
  font: large;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  color: rgb(227, 227, 227);
}
.contextMenu {
  background-color: #0a0a0a;
}
.contextMenuButtonDiv {
  display: flex;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  border-radius: 6px;
  font-size: 15px;
}
.svgButtonDiv {
  background-color: transparent;
}
button:hover {
  cursor: pointer;
}
.signupButton {
  background-color: #7b88ed;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 24px;
  border: none;
  color: rgb(28, 28, 28);
  transition: 0.15s all ease;
}
.signupButton:hover {
  background-color: #a8b0f7;
}
.loginButtonNavbar:hover {
  cursor: pointer;
  color: #b4bdff;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
::-webkit-scrollbar {
  display: none;
}
