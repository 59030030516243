.popupContainer {
  position: relative;
  animation: fadeIn 0.32s;
  /* overflow-y: hidden;
  overflow-x: hidden; */
  align-items: center;
  z-index: 1000;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.popupContent {
  font-family: sans-serif;
  font-weight: 100;
  font-size: 18px;
  padding: 20px;
  background: rgb(23, 23, 23);
  width: 350px;
  height: fit-content;
  position: fixed;
  color: #dddddd;
  text-align: center;
  border-radius: 10px;
  top: 180px;
  left: 0;
  right: 0;
  margin-inline: auto;
  animation: fadeIn2 0.32s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  overflow: visible;
  scrollbar-width: none;
  box-shadow: 0px 0px 47px 10000px rgba(0, 0, 0, 0.95);
}
.popupAnchor {
  position: relative;
  z-index: 10000;
}
.popupInstructions {
  position: absolute;
  color: grey;
  width: 300px;
  left: -340px;
  top: 90px;
  z-index: 100000000000;
  font-size: 16px;
}
.popupInstructions p {
  font-size: 15px !important;
}
.inputPromptSpan {
  background-color: #d9d9d9;
  color: black;
  font-weight: bold;
  border-left: 10px;
  border-right: 10px;
  border-color: #515dba;
  border-style: solid;
  border-radius: 3px;
  animation: 1s ease-in 0.1s infinite reverse both running glowOw;
}
@keyframes glowOw {
  0% {
    border-color: rgb(81, 93, 186, 0.2);
  }
  50% {
    border-color: #515dba;
  }
  100% {
    border-color: rgb(81, 93, 186, 0.2);
  }
}
.popupContent button {
  padding: 10px;
  font-size: 16px;
  font-weight: 100;
  color: #dddddd;
  border: 1px solid rgb(11, 11, 11);
  border-radius: 10px;
  background-color: rgb(11, 11, 11);
  margin-left: auto;
  margin-right: auto;
  margin: 10px 2px 2px 2px;
}
@keyframes fadeIn2 {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.popupContent .selectModality {
  width: 100px;
}
.popupContent p {
  font-size: 19px;
  padding: 0px;
  margin: 10px;
}
.popupContent textarea {
  font-family: sans-serif;
  font-weight: 100;
  font-size: 16px;
  padding: 10px;
  margin: 7px;
  border: 0.5px solid rgb(10, 10, 10);
  border-radius: 10px;
  background: rgb(10, 10, 10);
  color: #dddddd;
  outline: none;
  transition: all 0.15s ease-in-out;
  width: 90%;
  box-shadow: 0px 0px 12px 0.6px rgb(81, 93, 186, 0.4);
}
.popupContent textarea:focus {
  border: 0.5px solid rgb(81, 93, 186, 0.4);
  box-shadow: 0px 0px 12px 1px rgb(81, 93, 186, 0.6);
  outline: none;
}
.popupContent textarea:hover {
  border: 0.5px solid rgb(81, 93, 186, 0.6);
}
.popupContent h1 {
  font-weight: 500;
  font-size: 34px;
  margin: 0px;
  margin-bottom: 15px;
}
h5 {
  font-weight: 100;
  color: grey;
  padding-bottom: 5px;
  margin: 0px;
}
.protocolSelect {
  width: 300px;
  min-height: 30px;
  font-size: 13px;
  color: rgb(222, 222, 222);
  background-color: black;
  margin: 8px;
}
.inputPromptOptions button {
  padding: 5px;
  margin: 3px;
}
