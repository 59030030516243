.sharedRybasContainer {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 50px;
  width: 1200px;
  padding: 15px;
  height: fit-content;
  /* background: rgb(19, 19, 19); */
  font-family: sans-serif;
  color: #dddddd;
  border-radius: 6px;
  animation: fadeIn 0.6s;
  text-align: center;
}
.searchBar {
  margin-bottom: 10px;
}
.searchBar input {
  width: 390px;
  height: 25px;
  border-radius: 9px;
  color: white;
  background: transparent;
}
.modalityForm {
  margin-bottom: 12px;
}
.filters {
  display: inline;
  justify-content: space-around;
}
.modalitySelect {
  margin-right: 10px;
}
.modalitySelect:focus {
  background-color: #515dba;
  outline: none;
}
.sharedRybaList {
  display: flex;
  flex-direction: column;
}
.sharedRyba {
  border: 0.5px solid rgb(63, 63, 63);
  font-size: 14px;
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 30px;
  margin-right: 30px;
  background: rgb(31, 31, 31);
  text-align: left;
  height: fit-content;
  display: flex;
}
.sharedRybaP {
  font-size: 15px;
  width: 250px;
  margin: 5px;
  padding: 2px;

  color: rgb(208, 187, 209);
}
.sharedRybaP:hover {
  color: rgb(149, 82, 152);
  cursor: pointer;
}
.sharedInfoContainer {
  display: flex;
}
.infoProtocol {
  width: 200px;
  border-left: 0.5px solid rgb(83, 83, 83);
  font-size: 14px;
  padding: 4px;
  max-height: fit-content;
  text-align: center;
}
.infoModaliity {
  text-align: center;
  width: 100px;
  padding: 4px;
  border-left: 0.5px solid rgb(83, 83, 83);
}
.infoNumberTimesShared {
  text-align: center;
  width: 100px;
  padding: 4px;
  border-left: 0.5px solid rgb(83, 83, 83);
}
.infoAuthor {
  text-align: center;
  width: 195px;
  padding: 4px;
  border-left: 0.5px solid rgb(83, 83, 83);
}
.infoCreatedAt {
  text-align: center;
  width: 80px;
  padding: 4px;
  border-left: 0.5px solid rgb(83, 83, 83);
}
.infoRating {
  width: 60px;
  padding: 4px;
  text-align: center;
  border-left: 0.5px solid rgb(83, 83, 83);
}
.infoRatingAmount {
  width: 80px;
  padding: 4px;
  text-align: center;
  border-left: 0.5px solid rgb(83, 83, 83);
}

.popupContainerShared {
  width: 100vw;
  height: 100vh;
  position: fixed;

  z-index: 0;
  top: 0px;
  left: 0px;
  animation: fadeIn 0.4s;
  background-color: rgba(0, 0, 0, 0.8);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.popupContentShared {
  z-index: 70;
  font-size: 12px;
  background-color: rgb(228, 228, 228);
  width: 600px;
  height: fit-content;
  position: absolute;
  color: black;
  text-align: left;
  border-radius: 4px;
  padding: 18px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  animation: fadeIn2 0.4s;
  overflow: scroll;
  font-family: verdana;
  /* font-size: 14px; */
}
.popupContentShared p {
  margin: 0px;
  padding-bottom: 0px;
}
.popupContentShared button {
  border: 1px solid rgb(89, 89, 89);
  background-color: rgb(25, 25, 25);
  color: rgb(212, 212, 212);
  margin-bottom: 15px;
}
.buttonsContainer {
  padding: 3px;
  margin: 10px;
  border: 1px solid black;
  border-radius: 2px;
  background-color: rgb(190, 190, 190);
}
.buttonsContainer p {
  font-weight: 700;
  padding-right: 10px;
}

.buttonsContainer span {
  font-weight: 300;
}

.buttonsContainer:empty {
  display: none;
}
.segmentTitleSpan {
  font-weight: 600;

  border-radius: 4px;
  padding: 1px;
}
.exampleRYba {
  background-color: rgb(40, 40, 40);
  padding: 10px;
  margin: 10px;
  height: 200px;
}
