.savedList {
  color: rgb(198, 198, 198);
  font-size: 1rem;
}
.savedList h1 {
  margin-bottom: 30px;
  font-weight: 500;
}
.savedShapka {
  font-size: 1.1rem;
  color: rgb(180, 180, 180);
}
.savedName {
  width: 200px;
}
.savedName {
  transition: 0.15s all ease;
  color: rgb(255, 255, 255);
  margin-bottom: 10px;
}

.savedNamehover:hover {
  cursor: pointer;
  color: #515dba;
}
.savedModality {
  width: 80px;
  text-align: center;
  margin-right: 30px;
}
.savedProtocol {
  width: 200px;
  text-align: center;
}
.savedProtocolTable {
  font-size: 0.8rem;
}
.savedDate {
  width: 75px;
  text-align: center;
}
.savedTime {
  width: 100px;
  text-align: center;
}
.savedDelete {
  width: 110px;
  text-align: center;
  transition: all 0.15s ease;
}
.savedDelete:hover {
  color: rgb(231, 99, 121);
  cursor: pointer;
}
.skachat:hover {
  color: rgb(41, 156, 53);
  cursor: pointer;
}
.rybaListDividerContainer {
  display: flex;
}
