.privacy-policy-container {
  max-width: 800px; /* Limit width for readability */
  margin: 40px auto; /* Center the content */
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.3;
  color: #c7c7c7;
  background-color: transparent;
  border-radius: 8px;
  font-weight: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #dadada;
}

.privacy-policy-container h2 {
  margin-top: 30px;
  color: #1e88e5; /* Accent color for headings */
  border-bottom: 2px solid #1e88e5;
  padding-bottom: 5px;
}

.privacy-policy-container p {
  margin: 15px 0;
}

.privacy-policy-container ul {
  margin-left: 20px;
}

.privacy-policy-container li {
  margin: 10px 0;
}

.privacy-policy-container a {
  color: #1e88e5;
  text-decoration: none;
  transition: color 0.3s ease;
}

.privacy-policy-container a:hover {
  color: #1565c0; /* Darker blue on hover */
}
