.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.window {
  background-color: #222;
  border-radius: 8px;
  border: 1px solid rgb(74, 74, 74);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 500px;
  animation: slideIn 0.3s ease-in-out;
  color: #fff;
  text-align: center;
  overflow: hidden; /*  Prevent content from overflowing rounded corners */
  font-weight: 100;
}

.header {
  padding: 16px;
}

.title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.content {
  padding: 24px;
  font-size: 1rem;
  line-height: 1.6;
}

.button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
  margin: auto;
}

.button:hover {
  background-color: #367c39;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
