/* Keyframes for footer fade-in */
@keyframes fadeInFooter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footer {
  background-color: transparent; /* Dark background */
  color: #ffffff; /* Light text */
  padding: 20px 0; /* Vertical padding */
  text-align: center; /* Center-align content */
  position: relative;
  bottom: 0;
  font-weight: 100;
  width: 100%;
  border-top: 1px solid rgb(51, 51, 51, 0.5); /* Subtle border for separation */
  margin-top: 300px; /* Push footer to the bottom of the page */
  animation: fadeIn 0.5s ease-in-out 2s forwards; /* Delay of 2 seconds */
}

.footer p {
  margin-bottom: 10px;
}

.footer a {
  color: #bb86fc; /* Accent color for links */
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #c9afe0; /* Slightly lighter on hover */
}
